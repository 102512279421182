import { SxProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface IStandartSpinnder {
  style?: React.CSSProperties | SxProps;
  [x: string]: any;
}

const StandartSpinner: React.FC<IStandartSpinnder> = ({
  style = null,
  ...props
}) => {
  return (
    <CircularProgress
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        ...style,
      }}
      {...props}
    />
  );
};

export default StandartSpinner;
