type IconProps = {
  mode: "light" | "dark";
};

const EditIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7846 0L0.658936 12.0565V15.2569H3.89477L15 3.22093L11.7846 0Z"
        fill={mode === "light" ? "#071224" : "#F8FBFE"}
      />
      <path
        d="M13.9385 7.78419L8.90137 2.74707"
        stroke={mode === "light" ? "#F8FBFE" : "#071224"}
        stroke-width="1.83168"
      />
      <rect
        x="0.658936"
        y="17"
        width="13.7376"
        height="2.74752"
        fill={mode === "light" ? "#071224" : "#F8FBFE"}
      />
    </svg>
  );
};

export default EditIcon;
