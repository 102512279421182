import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import { connectionStart, connectionStop } from "../slices/hub/hubSlice";

import { HubConnection } from "@microsoft/signalr";
import { preTripHubListener } from "./listeners/preTripHubListener";
import { HUB_SIGNAL_R } from "@constants/api/pretrip_api";
const signalR = require("@microsoft/signalr");

const hubMiddleware: Middleware = (store: MiddlewareAPI) => {
  let hubConnection: HubConnection;

  return (next) => (action) => {
    let state = store.getState();
    let id = null;
    let role = "";
    let url = null;

    const {
      profile: { profileInfo },
    } = state;

    id = profileInfo?.id;
    role = profileInfo?.role;
    url = `${HUB_SIGNAL_R}?userId=${id}`;

    if (connectionStart.match(action)) {
      try {
        hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(url)
          .withAutomaticReconnect()
          .configureLogging(signalR.LogLevel.Information)
          .build();

        preTripHubListener(hubConnection, store, role);

        hubConnection
          .start()
          .then(() => {
            console.log("Connection started!");
          })
          .catch((e: any) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }

    if (connectionStop.match(action)) {
      try {
        hubConnection
          .stop()
          .then(() => {
            console.log("Connection stopped!");
          })
          .catch((err: any) => {
            console.log(err);
            console.log("Error while establishing disconnection :(");
          });
      } catch (e) {
        console.log(e);
      }
    }

    next(action);
  };
};

export default hubMiddleware;
