import { IconProps } from "./types";

const MedicsIcon: React.FC<IconProps> = ({ isActive }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0271948 13.6605H6.85667H7.07705H9V10C9 9.59985 9.11752 9.22714 9.31992 8.91451C8.85874 8.55171 8.64321 7.90839 8.83945 7.71215C8.86497 7.68663 8.89935 7.6552 8.94021 7.61786L8.94031 7.61777L8.94035 7.61773C9.25229 7.33263 9.94099 6.70318 9.94099 5.72938V2.86537C9.94099 2.14043 9.45796 1.34649 8.83945 0.882603C8.22097 0.41874 7.51767 0.221697 7.07705 0.22168V0.2188C6.38875 0.2188 5.71278 0.418719 5.09427 0.882603C4.47575 1.34649 3.99273 2.14043 3.99273 2.86537V5.72938C3.99273 6.70318 4.68143 7.33263 4.99337 7.61773C5.03428 7.65513 5.06872 7.6866 5.09427 7.71215C5.31457 7.93246 5.01592 8.71622 4.43334 9.03399C4.18166 9.17128 3.92862 9.30626 3.6756 9.44123L3.67559 9.44124C2.64846 9.98916 1.62174 10.5369 0.688118 11.2371C-0.0168668 11.7658 -0.0462412 13.073 0.0271948 13.6605ZM10.4606 9H15.565V10.9029C15.565 11.1573 15.7712 11.3635 16.0255 11.3635H18V18.5394C18 18.7938 17.7938 19 17.5394 19H10V9.46057C10 9.2062 10.2062 9 10.4606 9ZM13.6364 11.8571H12.5455V12.5714H13.6364V13.6429H14.3636V12.5714H15.4545V11.8571H14.3636V10.7857H13.6364V11.8571ZM11.8182 17.2143H16.1547V16.5H11.8182V17.2143ZM16.1818 15.7857V15.0714H11.8182V15.7857H16.1818ZM16.6152 10.8124H18L16.1547 9V10.3518C16.1547 10.6062 16.3609 10.8124 16.6152 10.8124Z"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
    </svg>
  );
};

export default MedicsIcon;
