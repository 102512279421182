import { Routes, Route } from "react-router-dom";
import { lazy, useEffect } from "react";

import { Error404, ErrorBoundary } from "@components";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "@containers/Login";

import { getProfileInfo } from "@redux/slices/profile/thunks";
import { useAppDispatch } from "@utils/hooks/redux-hooks";
import { useAuth } from "@utils/hooks/useAuth";

import TokenService from "@services/auth/TokenService";

const ProfileSettingsContainer = lazy(
  () => import("@containers/ProfileSettings")
);

// root
const ConditionMonitoringContainer = lazy(
  () => import("@containers/AdminPanel/ConditionMonitoring")
);

const UserMonitoringContainer = lazy(
  () => import("@containers/AdminPanel/UserMonitoring")
);

const Companies = lazy(
  () => import("@containers/AdminPanel/EditData/Companies")
);
const Jobs = lazy(() => import("@containers/AdminPanel/EditData/Jobs"));

// editOrgUsers in root
const SupervisorUsers = lazy(
  () => import("@containers/AdminPanel/EditUsers/SupervisorUsers")
);
const AdminUsers = lazy(
  () => import("@containers/AdminPanel/EditUsers/AdminUsers")
);

const MedicalUsers = lazy(
  () => import("@containers/AdminPanel/EditUsers/MedicalUsers")
);
const DriverUsers = lazy(
  () => import("@containers/AdminPanel/EditUsers/DriverUsers")
);

// medical & supervisor
const DriversContainer = lazy(
  () => import("@containers/OrgPanel/EditOrgUser/DriversContainer")
);
const MedicalCards = lazy(() => import("@containers/OrgPanel/MedicalCards"));
const MedicalsContainer = lazy(
  () => import("@containers/OrgPanel/EditOrgUser/MedicalsContainer")
);

const Roles = {
  root: "root",
  medicalworker: "medicalworker",
  supervisor: "supervisor",
  driver: "driver",
};

const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const user = useAuth();
  const session = TokenService.getSession();

  useEffect(() => {
    if (!user && session) {
      dispatch(getProfileInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="*" element={<Error404 />} />

        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                Roles.root,
                Roles.medicalworker,
                Roles.supervisor,
                Roles.driver,
              ]}
            />
          }
        >
          <Route path="/settings" element={<ProfileSettingsContainer />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[Roles.root]} />}>
          <Route path="/control" element={<ConditionMonitoringContainer />} />
          <Route path="/users" element={<UserMonitoringContainer />} />
          <Route path="/edit-supervisor" element={<SupervisorUsers />} />
          <Route path="/edit-developer" element={<AdminUsers />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/companies" element={<Companies />} />
        </Route>

        <Route
          element={
            <PrivateRoute allowedRoles={[Roles.root, Roles.supervisor]} />
          }
        >
          <Route path="/edit-medical" element={<MedicalUsers />} />
          <Route path="/edit-driver" element={<DriverUsers />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[Roles.medicalworker, Roles.supervisor]}
            />
          }
        >
          <Route path="/drivers" element={<DriversContainer />} />
        </Route>

        <Route
          element={
            <PrivateRoute
              allowedRoles={[
                Roles.medicalworker,
                Roles.supervisor,
                Roles.driver,
              ]}
            />
          }
        >
          <Route path="/medical-cards" element={<MedicalCards />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[Roles.supervisor]} />}>
          <Route path="/medicals" element={<MedicalsContainer />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
