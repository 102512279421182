import { Box, BoxProps, Grid, GridProps, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import loginImage from "@assets/login/loginBack.jpg";

import { StandartTextField, PasswordTextField } from "@components";
import { PasswordTextFieldProps } from "src/components/TextField/PasswordTextField";

type FormContainerProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
};

export const StyledContainer = styled((props: GridProps) => (
  <Grid {...props} />
))(({ theme }) => ({
  height: "100%",
  padding: "3rem",
  backgroundImage: `url(${loginImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
}));

export const StyledSignInContainer = styled(
  ({ children, onSubmit, ...props }: FormContainerProps) => (
    <form onSubmit={onSubmit} {...props}>
      {children}
    </form>
  )
)(({ theme }) => ({
  width: "45.8rem",
  borderRadius: "1.5rem",
  padding: "5.4rem 3.7rem 3.7rem 3.7rem",
  background: theme.palette.mode === "light" ? "#FFFFFF" : "#050C19",
  textAlign: "center",
  position: "absolute",
  left: "12%",
  top: "50%",
  transform: "translateY(-50%)",
}));

export const StyledSignInHeader = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  [theme.breakpoints.only("xl")]: {
    marginBottom: "6.3rem",
  },
  [theme.breakpoints.only("lg")]: {
    marginBottom: "5.3rem",
  },
  [theme.breakpoints.only("md")]: {
    marginBottom: "4.3rem",
  },
  [theme.breakpoints.only("sm")]: {
    marginBottom: "3.3rem",
  },
  [theme.breakpoints.only("xs")]: {
    marginBottom: "2.3rem",
  },

  "& > svg": {
    [theme.breakpoints.only("xl")]: {
      marginBottom: "3.1rem",
    },
    [theme.breakpoints.only("lg")]: {
      marginBottom: "2.5rem",
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: "2.1rem",
    },
    [theme.breakpoints.only("sm")]: {
      marginBottom: "1.8rem",
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: "1.5rem",
    },
  },
}));

export const StyledSignInInputs = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  "& > :not(:last-child)": {
    marginBottom: "2rem",
  },
}));

export const StyledSignInButtons = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  marginTop: "6rem",
}));

export const StyledLoginTextField = styled((props: TextFieldProps) => (
  <StandartTextField {...props} />
))(({ theme }) => ({
  borderRadius: "3rem",
  fontWeight: "400",

  "& .MuiOutlinedInput-root": {
    background: "transparent",
  },

  "& .MuiInputBase-root": {
    border: "0",
  },

  "input:-webkit-autofill, input:-webkit-autofill:hover,input:-webkit-autofill:focus, textarea:-webkit-autofill":
    {
      boxShadow: `0 0 0px 1000px ${
        theme.palette.mode === "light" ? "#ffff" : "#050C19"
      } inset`,
      transition: "background-color 5000s ease-in-out 0s",
    },
}));

export const StyledPasswordTextField = styled(
  (props: PasswordTextFieldProps) => <PasswordTextField {...props} />
)(({ theme }) => ({
  border: "0",
  borderRadius: "3rem",

  fontWeight: "400",

  "& .MuiOutlinedInput-root": {
    background: "transparent",
  },

  "& .MuiInputBase-root": {
    border: "0",
  },

  "input:-webkit-autofill, input:-webkit-autofill:hover,input:-webkit-autofill:focus, textarea:-webkit-autofill":
    {
      boxShadow: `0 0 0px 1000px ${
        theme.palette.mode === "light" ? "#ffff" : "#050C19"
      } inset`,
      transition: "background-color 5000s ease-in-out 0s",
    },
}));
