import axios from "axios";
import TokenService from "./TokenService";
import AuthService from "./AuthService";
import { useContext } from "react";
import { TokenInMemoryContext } from "./TokenInMemoryContext";

const AxiosInterceptor = ({ children }: { children: JSX.Element }) => {
  const { token, logoutHandler } = useContext(TokenInMemoryContext);

  axios.interceptors.request.use(
    (config) => {
      if (token?.current !== "" && config.headers) {
        config.headers["Authorization"] = "Bearer " + token?.current;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig?._retry) {
          // if (TokenService.getRefreshToken()) {
            originalConfig._retry = true;
            try {
              const refreshedTokenInfo = await AuthService.refreshToken();
              token!.current = refreshedTokenInfo.access_token;
              // TokenService.updateRefreshToken(refreshedTokenInfo);
              return axios(originalConfig);
            } catch (_error) {
              logoutHandler();
              return Promise.reject(_error);
            }
          // } else {
          //   logoutHandler();
          //   return;
          // }
        }
      }

      return Promise.reject(err);
    }
  );

  return children;
};

export { AxiosInterceptor };
