import AppRoutes from "@routes/Routes";

import {
  SettingsConsumer,
  SettingsProvider,
} from "@themes/context/themeContext";
import ThemeComponent from "@themes/ThemeComponent";

const App = () => {
  disableEmotionWarnings();

  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <ThemeComponent settings={settings}>
              <AppRoutes />
            </ThemeComponent>
          );
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
};
export default App;

// отключить спам ошибок :first-child от mui (из-за emotion пакета)
const disableEmotionWarnings = () => {
  /* eslint-disable no-console */
  const log = console.error.bind(console);
  console.error = (...args) => {
    /* eslint-enable no-console */
    if (
      args.indexOf("The pseudo class") &&
      args.indexOf(
        "is potentially unsafe when doing server-side rendering. Try changing it to"
      )
    ) {
      return;
    }
    log(...args);
  };
};
