// @ts-nocheck
let PRETRIP_BACKEND = null;
let HUB = null;

if (window._env_ !== undefined) {
  PRETRIP_BACKEND = window._env_.REACT_APP_PRETRIP_API_URL;
  HUB = window._env_.REACT_APP_HUB;
} else {
  PRETRIP_BACKEND = process.env.REACT_APP_PRETRIP_API_URL;
  HUB = process.env.REACT_APP_HUB;
}

// ---------------------------- PRETRIP ENTITY ---------------------------
export const PRETRIP = `${PRETRIP_BACKEND}/PreTrip`;
export const MEDICAL_USER_PRETRIP_CARDS = `${PRETRIP}/cards`;
export const DRIVER_USER_PRETRIP_CARDS = `${PRETRIP}/user/cards`; // для Driver'а пока не используется, мб в дальшнейшем
export const USER_CARD_BY_ID = `${PRETRIP}/card`;

export const PRETRIPHUB = `${PRETRIP_BACKEND}/PreTripHub`;
export const MEDICAL_SET_COMMENT = `${PRETRIPHUB}/set-comment`;
export const MEDICAL_UPDATE_STATUS = `${PRETRIPHUB}/set-status`;

export const USER_STATISTIC = `${PRETRIP_BACKEND}/Statistic/statistic`;

export const HUB_SIGNAL_R = `${HUB}`;
// --------------------------------------------------------------------------
