import { configureStore, combineReducers } from "@reduxjs/toolkit";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import usersReducer from "./slices/users/usersSlice";
import preTripReducer from "./slices/preTrip/preTripSlice";
import toastReducer from "./slices/toast/toastSlice";
import profileReducer from "./slices/profile/profileSlice";
import hubReducer from "./slices/hub/hubSlice";
import hubMiddleware from "./hubMiddleware";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const rootPersistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["auth", "users", "preTrip", "toast", "profile"],
};

const reducers = combineReducers({
  profile: profileReducer,
  preTrip: preTripReducer,
  toast: toastReducer,
  users: usersReducer,
  hub: hubReducer,
});

const persistedReducer = persistReducer<RootReducer>(
  rootPersistConfig,
  reducers
);

export const createReduxStore = (initialState = {}) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([hubMiddleware]),
  });
};

const store = createReduxStore();

export type RootReducer = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof configureStore>;

export default store;
