import { useLocation } from "react-router-dom";
import {
  StyledLinkContainer,
  StyledLink,
  StyledBackgroundImage,
} from "./style";

import navlink from "@assets/Sidebar/navlink.png";

type NavLinkProps = {
  to: string;
  exact?: boolean;
  color?: any;
  children: (isActive: boolean) => React.ReactNode;
  [x: string]: any;
};

const NavLink: React.FC<NavLinkProps> = ({
  to,
  exact = false,
  color,
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const isActive = exact ? pathname === to : pathname.startsWith(to);

  return (
    <StyledLinkContainer>
      <StyledLink
        to={to}
        color={color}
        active={isActive ? true : false}
        {...rest}
      >
        {children(isActive)}
      </StyledLink>
      {isActive && <StyledBackgroundImage src={navlink} alt="navlink-image" />}
    </StyledLinkContainer>
  );
};

export default NavLink;
