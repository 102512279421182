import { Components, Theme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const Calendar = (theme: Theme): Components<Theme> => {
  return {
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: theme.palette.mode === "light" ? "#071224" : "#ffff",
        },
      },
    },

    MuiDateTimePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            InputLabelProps: {
              color: "labelPrimary",
            },
          },
        },
      },
    },
  };
};

export default Calendar;
