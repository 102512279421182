
const CapslockIcon = () => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.05 7.55857V15.0204C11.05 15.8724 10.3558 16.5628 9.5 16.5628H5.5C4.64421 16.5628 3.95002 15.8724 3.95002 15.0204L3.95 13.4674L3.95 13.4367L3.95 13.3552L3.95 10.2818L3.95001 8.36328L3.95002 7.74242L3.95002 7.56955V7.55857H1.50002C1.27729 7.55857 1.0764 7.42483 0.99148 7.21963C0.991479 7.21963 0.991479 7.21963 0.991479 7.21963L1.03767 7.20052L11.05 7.55857ZM11.05 7.55857H13.5C13.7227 7.55857 13.9236 7.42483 14.0086 7.21964L14.0086 7.21962C14.0935 7.01438 14.0456 6.77847 13.8872 6.62227L8.59132 1.39712C7.98714 0.800959 7.01295 0.800963 6.40868 1.39712L6.4438 1.43271L6.40868 1.39712L1.11282 6.62227L1.14794 6.65786L11.05 7.55857ZM5.05002 13.915L5.05 12.4418L5.05002 7.0111C5.05002 6.7085 4.80353 6.46363 4.50002 6.46363H2.83617L7.18301 2.17478C7.18301 2.17477 7.18301 2.17477 7.18302 2.17477C7.35855 2.00166 7.64156 2.00167 7.81698 2.17477L7.8521 2.13918L7.81698 2.17477L12.1638 6.46363H10.5C10.1965 6.46363 9.95 6.7085 9.95 7.0111V15.0204C9.95 15.2672 9.74882 15.4678 9.5 15.4678H5.5C5.25125 15.4678 5.05002 15.2672 5.05002 15.0204L5.05002 14.0272L5.05002 13.9456V13.915Z"
        fill="#071224"
        stroke="#071224"
        stroke-width="0.1"
      />
      <path
        d="M4.5 17.9551C4.19648 17.9551 3.95 18.2 3.95 18.5026C3.95 18.8051 4.19648 19.0501 4.5 19.0501H10.5C10.8035 19.0501 11.05 18.8052 11.05 18.5026C11.05 18.2 10.8035 17.9551 10.5 17.9551H4.5Z"
        fill="#071224"
        stroke="#071224"
        stroke-width="0.1"
      />
    </svg>
  );
};

export default CapslockIcon;
