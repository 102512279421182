const CheckedBoxIcon: React.FC<FoundationApp.IconSVG> = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2.53333" fill="#E7F4FF" />
      <path
        d="M5 9.47059L8.46667 13L14 7"
        stroke="black"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CheckedBoxIcon;
