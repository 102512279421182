import { Theme } from "@mui/material/styles";

const Typography = (theme: Theme) => {
  return {
    h1: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "8rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "6.5rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "5.7rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "3.5rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "2.5rem",
      },
    },

    h2: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "4rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "3.6rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "3.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "2.8rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "2.4rem",
      },
    },

    h3: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "3rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "2.6rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "2.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.4rem",
      },
    },

    h4: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.1rem",
      },
    },

    body1: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1rem",
      },
    },

    body2: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1rem",
      },
    },

    body3: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2rem",
      },
    },

    number: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.1rem",
      },
    },

    button: {
      fontWeight: 400,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2rem",
      },
    },
  };
};

export default Typography;
