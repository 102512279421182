type EyeOpenIconProps = {
  color: string;
};

const EyeOpenIcon: React.FC<EyeOpenIconProps> = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.449631 5.76649C0.449467 5.76667 0.44932 5.76683 0.968777 6.24839L0.44932 5.76683L0 6.25151L0.452432 6.73328L0.968777 6.24839C0.452432 6.73328 0.452583 6.73345 0.452751 6.73362L0.453174 6.73407L0.454331 6.7353L0.457881 6.73906L0.469891 6.75167C0.480063 6.76231 0.494553 6.77736 0.513241 6.79654C0.550612 6.83488 0.604798 6.88974 0.674845 6.95875C0.81489 7.09672 1.01862 7.29153 1.27839 7.52423C1.79722 7.98899 2.54341 8.60832 3.45563 9.22853C5.26293 10.4573 7.80343 11.7455 10.5585 11.7455C15.6598 11.7455 18.2113 9.24478 20.4795 7.02175L20.48 7.02121C20.6179 6.88609 20.7547 6.75199 20.891 6.61954L21.3505 6.17301L20.9521 5.67118C19.613 3.98413 15.8817 0.614502 10.5585 0.614502C7.83121 0.614502 5.28764 1.94295 3.47523 3.2042C2.5593 3.84159 1.80721 4.47795 1.28356 4.95529C1.02136 5.19429 0.815459 5.39433 0.6739 5.5359C0.603096 5.60671 0.548317 5.66297 0.510568 5.70225C0.491691 5.72189 0.477066 5.7373 0.466818 5.74816L0.454742 5.76101L0.451193 5.76481L0.450047 5.76605L0.449631 5.76649ZM1.97739 6.2443C2.05176 6.31348 2.13397 6.38871 2.22364 6.46903C2.70715 6.90215 3.40352 7.48001 4.25215 8.05699C5.96655 9.22259 8.22092 10.3288 10.5585 10.3288C15.0161 10.3288 17.1976 8.24925 19.4278 6.06829C17.9725 4.48259 14.8178 2.03117 10.5585 2.03117C8.2623 2.03117 6.011 3.1655 4.28443 4.36702C3.43086 4.96102 2.72742 5.55605 2.23792 6.00225C2.14267 6.08908 2.05567 6.17014 1.97739 6.2443ZM12.5049 6.17989C12.5049 5.17394 11.6894 4.35846 10.6834 4.35846C9.67749 4.35846 8.86201 5.17394 8.86201 6.17989C8.86201 7.18583 9.67749 8.00131 10.6834 8.00131C11.6894 8.00131 12.5049 7.18583 12.5049 6.17989ZM13.9215 6.17989C13.9215 4.39154 12.4718 2.94179 10.6834 2.94179C8.89508 2.94179 7.44534 4.39154 7.44534 6.17989C7.44534 7.96824 8.89508 9.41798 10.6834 9.41798C12.4718 9.41798 13.9215 7.96824 13.9215 6.17989Z"
        fill={color}
      />
    </svg>
  );
};

export default EyeOpenIcon;
