import { useState } from "react";
import NavLink from "../Navlink";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";

import ControlIcon from "@assets/Sidebar/ControlIcon";
import UsersIcon from "@assets/Sidebar/UsersIcon";
import EditUsersIcon from "@assets/Sidebar/EditUsersIcon";
import DevsIcon from "@assets/Sidebar/DevsIcon";
import SupervisorsIcon from "@assets/Sidebar/SupervisorsIcon";
import MedicsIcon from "@assets/Sidebar/MedicsIcon";
import DriversIcon from "@assets/Sidebar/DriversIcon";
import EditDataIcon from "@assets/Sidebar/EditDataIcon";
import CompaniesIcon from "@assets/Sidebar/CompaniesIcon";
import JobsIcon from "@assets/Sidebar/JobsIcon";
import { Box, Tooltip } from "@mui/material";
import ChevronUpIcon from "@assets/Sidebar/ChevronUpIcon";
import ChevronDownIcon from "@assets/Sidebar/ChevronDownIcon";

const RootItem = () => {
  const [editUserSubList, setEditUserSubList] = useState(true);
  const [editDataSublist, setEditDataSublist] = useState(true);
  const handleEditUserSublistOpen = () => {
    setEditUserSubList(!editUserSubList);
  };
  const handleEditDataSublistOpen = () => {
    setEditDataSublist(!editDataSublist);
  };

  return (
    <>
      <NavLink to="/control">
        {(isActive) => (
          <ListItemButton
            disableRipple
            disableGutters
            sx={{ marginLeft: "3.8rem" }}
          >
            <ListItemIcon>
              <ControlIcon isActive={isActive} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ variant: "body2", fontWeight: "700" }}
              primary="Контроль выездов"
            />
          </ListItemButton>
        )}
      </NavLink>

      <NavLink to="/users">
        {(isActive) => (
          <ListItemButton
            disableRipple
            disableGutters
            sx={{ marginLeft: "3.8rem" }}
          >
            <ListItemIcon>
              <UsersIcon isActive={isActive} />
            </ListItemIcon>
            <ListItemText
              primary="Пользователи"
              primaryTypographyProps={{ variant: "body2", fontWeight: "700" }}
            />
          </ListItemButton>
        )}
      </NavLink>

      <List>
        <ListItemButton
          onClick={handleEditUserSublistOpen}
          disableRipple
          disableGutters
          sx={{ marginLeft: "3.8rem" }}
        >
          <ListItemIcon>
            <EditUsersIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "700",
                }}
              >
                Редактирование <br /> пользователей
                <Box sx={{ marginLeft: "3.3rem" }}>
                  {editUserSubList ? (
                    <Tooltip title={"Скрыть"}>
                      <Box>
                        <ChevronUpIcon />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Раскрыть"}>
                      <Box>
                        <ChevronDownIcon />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            }
            primaryTypographyProps={{ variant: "body2", color: "#7C8A9E" }}
          />
        </ListItemButton>
        <Collapse in={editUserSubList} timeout="auto" unmountOnExit>
          <NavLink to="/edit-developer">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <DevsIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Разработчики"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/edit-supervisor">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <SupervisorsIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Руководитель"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/edit-medical">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <MedicsIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Медик"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/edit-driver">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <DriversIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Водитель"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </Collapse>
      </List>
      <List>
        <ListItemButton
          onClick={handleEditDataSublistOpen}
          disableRipple
          disableGutters
          sx={{ marginLeft: "3.8rem" }}
        >
          <ListItemIcon>
            <EditDataIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "700",
                }}
              >
                Редактирование <br /> данных
                <Box sx={{ marginLeft: "3.3rem" }}>
                  {editDataSublist ? (
                    <Tooltip title={"Скрыть"}>
                      <Box>
                        <ChevronUpIcon />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Раскрыть"}>
                      <Box>
                        <ChevronDownIcon />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            }
            primaryTypographyProps={{
              variant: "body2",
              color: "#7C8A9E",
              marginRight: "3.3rem",
            }}
          />
        </ListItemButton>
        <Collapse in={editDataSublist} timeout="auto" unmountOnExit>
          <NavLink to="/companies">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <CompaniesIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Компании"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/jobs">
            {(isActive) => (
              <ListItemButton
                disableRipple
                disableGutters
                sx={{ marginLeft: "3.8rem" }}
              >
                <ListItemIcon>
                  <JobsIcon isActive={isActive} />
                </ListItemIcon>
                <ListItemText
                  primary="Должность"
                  primaryTypographyProps={{
                    variant: "body2",
                    fontWeight: "700",
                  }}
                />
              </ListItemButton>
            )}
          </NavLink>
        </Collapse>
      </List>
    </>
  );
};

export default RootItem;
