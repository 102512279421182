import { Settings } from "./context/themeContext";

import Typography from "./typography";
import Overrides from "./overrides";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import themeOptions from "./ThemeOptions";
import { ruRU } from "@mui/material/locale";

type Props = {
  settings: Settings;
  children: React.ReactNode;
};

const ThemeComponent = (props: Props) => {
  const { settings, children } = props;

  const coreThemeConfig = themeOptions(settings);

  let theme = createTheme(coreThemeConfig);

  const tempTheme = createTheme(theme, {
    typography: { ...Typography(theme) },
  });

  theme = createTheme(
    tempTheme,
    { components: { ...Overrides(tempTheme) } },
    ruRU
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
