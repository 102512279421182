import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableFooter = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  gridArea: "footer",
  display: "grid",
  padding: "2rem 0",
  borderTop: `3px solid ${theme.palette.table.footer.border}`,
}));

export const StyledTablePaginationContainer = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& > .MuiBox-root": {
    display: "flex",
    alignItems: "center",
    marginLeft: "5rem",
  },
}));
