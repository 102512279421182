import { colors } from "./colors";

export const dark = {
  labelPrimary: {
    main: "#93A0B1",
    light: "#93A0B1",
    dark: "#93A0B1",
    contrastText: "#93A0B1",
  },

  primary: {
    main: colors.blue[300],
    contrastText: colors.white,
  },

  secondary: {
    main: "#3D424B",
    contrastText: "#F6FBFF",
  },

  success: {
    main: "#4C7CF8",
    contrastText: "#F6FBFF",
  },

  error: {
    main: "#FF4D4D",
    contrastText: "#F6FBFF",
  },

  text: {
    primary: "#FFFFFF",
    secondary: "#071224",
  },

  background: {
    paper: "#171C26",
    default: "#050C19",
  },

  agreeCardButton: {
    main: "#4C7CF8",
    contrastText: "#FFFFFF",
  },

  declineCardButton: {
    main: "#3D424B",
    contrastText: "#FFFFFF",
  },

  dialogButton: {
    main: "#3D424B",
    contrastText: "#FFFFFF",
  },

  //////////////////////////////

  navlink: {
    borderLeft: colors.blue[300],
  },

  actionBar: {
    chip: {
      bg: colors.blue[200],
      textColor: colors.black[100],
    },
  },

  chip: {
    bg: colors.gray[800],
  },

  dialog: {
    bg: colors.black[100],
  },

  pagination: {
    select: {
      border: colors.gray[300],
      iconColor: colors.white,
      textColor: colors.white,
    },
    item: {
      iconColor: colors.white,
    },
  },

  sidebar: {
    bg: colors.black[400],
  },

  table: {
    footer: {
      border: colors.gray[800],
    },
    header: {
      border: colors.gray[800],
    },
    row: {
      bg: colors.black[50],
      hover: {
        bg: colors.gray[600],
        chipTextColor: colors.white,
      },
    },
    selectedRow: {
      bg: colors.blue[200],
      textColor: colors.black[200],
    },
  },

  modal: {
    bg: colors.black[400],
    icon: colors.gray[600],
    textFieldBg: colors.gray[400],
    textFieldIcon: colors.gray[400],
  },

  scrollbar: {
    trackBg: colors.gray[800],
    thumbBg: colors.gray[100],
    thumbHover: colors.gray[600],
  },

  outlineInput: {
    bg: colors.black[50],
    color: colors.gray[400],
  },

  inputLabel: {
    color: colors.gray[400],
  },

  menu: {
    hover: colors.gray[600],
    selected: colors.gray[600],
    textColor: colors.gray[400],
    // scrollbarColor: colors.gray[800],
  },

  searchTextField: {
    bg: colors.green[50],
    textColor: colors.gray[700],
  },

  input: {
    textColor: colors.black[600],
    adornedEnd: {
      border: colors.gray[400],
    },
  },

  signin: {
    bg: colors.white,
  },

  card: {
    preliminary: {
      bad: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FF4C4CFF' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
      normal: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234C7CF8FF' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
    },
    agreed: {
      bad: "1px solid #FF4C4C",
      normal: "1px solid #4C7CF8",
    },
    archive: {
      bad: "#FF4C4C",
      normal: "#4C7CF8",
    },
  },
};
