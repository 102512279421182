import Grid, { GridProps } from "@mui/material/Grid";

import { styled } from "@mui/material/styles";

const ActionBarContainer = styled((props: GridProps) => (
  <Grid {...props} />
))(({ theme }) => ({
  flexWrap: "wrap",
  alignItems: "center",
  marginBottom: "4rem",

  [theme.breakpoints.only("xl")]: {
    gap: "3rem",
  },
  [theme.breakpoints.only("lg")]: {
    gap: "2rem",
  },
  [theme.breakpoints.only("md")]: {
    gap: "1rem",
  },
  [theme.breakpoints.only("sm")]: {
    gap: "1rem",
  },
  [theme.breakpoints.only("xs")]: {
    gap: "1rem",
  },
}));

export default ActionBarContainer;
