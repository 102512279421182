import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StandartTextField = styled(({ ...props }: TextFieldProps) => {
  return <TextField {...props} />;
})(({ theme }) => ({
  borderRadius: "2.5rem",
  "& fieldset": { border: "none" },
}));

export default StandartTextField;
