import { createContext, useRef } from "react";
import { getProfileInfo } from "@redux/slices/profile/thunks";
import { useAppDispatch } from "@utils/hooks/redux-hooks";
import TokenService from "@services/auth/TokenService";
import AuthService from "./AuthService";
import { resetProfileSlice } from "@redux/slices/profile/profileSlice";
import { notificationBuilder } from "@utils/notificationBuilder";
import { AuthErrors } from "@constants/notifications/errors";
import { resetPreTripSlice } from "@redux/slices/preTrip/preTripSlice";
import { resetUsersSlice } from "@redux/slices/users/usersSlice";

export type TokenInMemoryState = {
  loginHandler: (email: string, password: string) => void;
  logoutHandler: () => void;
  token: React.MutableRefObject<string | null> | null;
};

const initialContextState: TokenInMemoryState = {
  loginHandler: () => {},
  logoutHandler: () => {},
  token: null,
};

export const TokenInMemoryContext =
  createContext<TokenInMemoryState>(initialContextState);

const TokenInMemory = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const token = useRef<string | null>(null);

  const loginHandler = async (email: string, password: string) => {
    AuthService.login(email, password)
      .then((response) => {
        // TokenService.setRefreshToken(response);
        token.current = response.access_token;
      })
      .then(() => dispatch(getProfileInfo()))
      .then(() => {
        TokenService.setSession();
      })
      .catch((e) => {
        notificationBuilder(AuthErrors.LOGIN, "error", {
          dispatch,
        });
      });
  };

  const logoutHandler = () => {
    AuthService.logout().then(() => {
      token.current = null;
      dispatch(resetProfileSlice());
      dispatch(resetPreTripSlice());
      dispatch(resetUsersSlice());
      window.history.replaceState(null, "");
    });
  };

  return (
    <TokenInMemoryContext.Provider
      value={{
        token,
        loginHandler,
        logoutHandler,
      }}
    >
      {children}
    </TokenInMemoryContext.Provider>
  );
};

export default TokenInMemory;
