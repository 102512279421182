type IconProps = {
  mode: "light" | "dark";
};

const ClearIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="-8 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8145 10.8149L21.1854 21.1858"
        stroke={mode === "light" ? "#171C26" : "#171C26"}
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M10.8145 21.1855L21.1854 10.8146"
        stroke={mode === "light" ? "#171C26" : "#171C26"}
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default ClearIcon;
