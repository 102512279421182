import {
  Chip as MUIChip,
  ChipProps,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { ChipCloseIcon } from "../../assets/IconsSVG";

export type CustomChipProps = {} & ChipProps;

const Chip = styled((props: CustomChipProps) => (
  <MUIChip
    variant="outlined"
    deleteIcon={
      <Tooltip title={"Очистить"}>
        <Box>
          <IconButton onClick={props.onDelete}>
            <ChipCloseIcon />
          </IconButton>
        </Box>
      </Tooltip>
    }
    {...props}
  />
))(({ theme }) => ({
  ...theme.typography.body1,
  height: "100%",
  border: 0,
  borderRadius: "3rem",
  background: theme.palette.chip.bg,

  span: {
    padding: "1rem 0 1rem 1.7rem",
  },
  "& .MuiIconButton-root": {
    marginLeft: "1rem",
  },
}));

export default Chip;
