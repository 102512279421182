const EditDataIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73869 0H0.46057C0.206205 0 0 0.206204 0 0.46057V16H13.5394C13.7938 16 14 15.7938 14 15.5394V3.78155H10.1993C9.94489 3.78155 9.73869 3.57535 9.73869 3.32098V0ZM9.73869 9.14286H3.18182V8H9.73869V9.14286ZM9.73869 5.71429V6.85714H3.18182V5.71429H9.73869ZM14 2.89981H11.2312C10.9769 2.89981 10.7707 2.6936 10.7707 2.43924V0L14 2.89981Z"
        fill="#7C8A9E"
      />
      <mask
        id="path-2-outside-1_52_8018"
        maskUnits="userSpaceOnUse"
        x="6.4707"
        y="5.41187"
        width="14"
        height="14"
        fill="black"
      >
        <rect fill="white" x="6.4707" y="5.41187" width="14" height="14" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.6559 9.07639L16.1919 7.41187L18.4234 9.64718L16.8893 11.3098L14.6559 9.07639ZM13.9771 9.81189L8.4707 15.779V18.0001H10.7164L16.2106 12.0454L13.9771 9.81189Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6559 9.07639L16.1919 7.41187L18.4234 9.64718L16.8893 11.3098L14.6559 9.07639ZM13.9771 9.81189L8.4707 15.779V18.0001H10.7164L16.2106 12.0454L13.9771 9.81189Z"
        fill="#7C8A9E"
      />
      <path
        d="M16.1919 7.41187L17.0569 6.54837L16.1572 5.64718L15.2937 6.58299L16.1919 7.41187ZM14.6559 9.07639L13.7576 8.24752L12.9614 9.11039L13.7916 9.94063L14.6559 9.07639ZM18.4234 9.64718L19.3216 10.476L20.1171 9.61386L19.2883 8.78368L18.4234 9.64718ZM16.8893 11.3098L16.025 12.1741L16.9247 13.0738L17.7876 12.1386L16.8893 11.3098ZM13.9771 9.81189L14.8414 8.94765L13.9417 8.04801L13.0789 8.98301L13.9771 9.81189ZM8.4707 15.779L7.57249 14.9501L7.24848 15.3012V15.779H8.4707ZM8.4707 18.0001H7.24848V19.2223H8.4707V18.0001ZM10.7164 18.0001V19.2223H11.2517L11.6146 18.8289L10.7164 18.0001ZM16.2106 12.0454L17.1089 12.8742L17.905 12.0113L17.0749 11.1811L16.2106 12.0454ZM15.2937 6.58299L13.7576 8.24752L15.5541 9.90526L17.0901 8.24074L15.2937 6.58299ZM19.2883 8.78368L17.0569 6.54837L15.3269 8.27536L17.5584 10.5107L19.2883 8.78368ZM17.7876 12.1386L19.3216 10.476L17.5251 8.81837L15.991 10.481L17.7876 12.1386ZM13.7916 9.94063L16.025 12.1741L17.7535 10.4456L15.5201 8.21215L13.7916 9.94063ZM13.0789 8.98301L7.57249 14.9501L9.36892 16.6079L14.8754 10.6408L13.0789 8.98301ZM7.24848 15.779V18.0001H9.69293V15.779H7.24848ZM8.4707 19.2223H10.7164V16.7779H8.4707V19.2223ZM11.6146 18.8289L17.1089 12.8742L15.3123 11.2166L9.81809 17.1713L11.6146 18.8289ZM17.0749 11.1811L14.8414 8.94765L13.1129 10.6761L15.3464 12.9096L17.0749 11.1811Z"
        fill="#142939"
        mask="url(#path-2-outside-1_52_8018)"
      />
    </svg>
  );
};

export default EditDataIcon;
