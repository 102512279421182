import React from "react";

const ChevronUpIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91992 13L10.9199 7L16.9199 13"
        stroke="#7C8A9E"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ChevronUpIcon;
