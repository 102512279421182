import { Box, BoxProps, Typography } from "@mui/material";

import StyledTableRow from "./style";
import { OverflowTooltip } from "@components";

type TableRowProps = {
  rows: FoundationApp.TableRow[];
} & BoxProps;

const TableRow: React.FC<TableRowProps> = ({ rows, ...props }) => {
  return (
    <StyledTableRow rows={rows} {...props}>
      {rows.map((row) => {
        if (row.hidden) {
          return null;
        }
        if (row.renderItem) {
          return row.renderItem;
        } else {
          return (
            <Box id={row.gridArea}>
              <OverflowTooltip title={row.fieldData} placement="bottom">
                <Typography component="span" variant="body3" noWrap>
                  {row.fieldData}
                </Typography>
              </OverflowTooltip>
            </Box>
          );
        }
      })}
    </StyledTableRow>
  );
};

export default TableRow;
