import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ToastState = {
  notification: FoundationApp.Notification;
};

const initialState: ToastState = {
  notification: {
    message: "",
    type: "warning",
    open: false,
  },
};
const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<FoundationApp.Notification>) => {
      state.notification.open = true;
      state.notification.message = action.payload?.message;
      state.notification.type = action.payload?.type;
    },
    clearMessage: (state, _: PayloadAction<void>) => {
      state.notification.open = false;
    },
  },
});

export const selectNotification = (state: { toast: ToastState }) =>
  state.toast?.notification || "";

const { reducer, actions } = toastSlice;
export const { setMessage, clearMessage } = actions;
export default reducer;
