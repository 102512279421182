type PersonIconProps = {
  color: string;
};

const PersonIcon: React.FC<PersonIconProps> = ({ color }) => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3369 4.6818C11.3369 6.29722 10.128 7.44973 8.8246 7.44973C7.52116 7.44973 6.3123 6.29722 6.3123 4.6818C6.3123 3.06638 7.52116 1.91387 8.8246 1.91387C10.128 1.91387 11.3369 3.06638 11.3369 4.6818ZM12.7869 4.6818C12.7869 7.0113 11.0129 8.89973 8.8246 8.89973C6.63628 8.89973 4.8623 7.0113 4.8623 4.6818C4.8623 2.3523 6.63628 0.463867 8.8246 0.463867C11.0129 0.463867 12.7869 2.3523 12.7869 4.6818ZM4.2487 10.4752C2.43647 10.4752 0.967377 11.9443 0.967377 13.7565H2.41738C2.41738 12.7451 3.23729 11.9252 4.2487 11.9252H13.7071C14.7185 11.9252 15.5384 12.7451 15.5384 13.7565H16.9884C16.9884 11.9443 15.5193 10.4752 13.7071 10.4752H4.2487Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonIcon;
