const ChipCloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.05026 11.9485L11.9498 2.04905"
        stroke="#071224"
        stroke-width="1.48235"
        stroke-linecap="round"
      />
      <path
        d="M11.9498 11.9485L2.05026 2.04905"
        stroke="#071224"
        stroke-width="1.48235"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default ChipCloseIcon;
