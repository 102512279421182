import { Components, Theme } from "@mui/material/styles";

const Checkbox = (theme: Theme): Components<Theme> => {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: "1.5rem",
        },
        checked: {
          border: "red",
          background: "red",
        },
      },
    },
  };
};

export default Checkbox;
