import { Component } from "react";
import ErrorBoundaryUI from "./ErrorBoundaryUI";

type ErrorBoundaryProps = { children: React.ReactNode };
type ErrorBoundaryPropsState = { hasError: boolean };

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryPropsState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryUI />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
