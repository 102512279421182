import { Theme } from "@emotion/react";
import { Components } from "@mui/material";

const List = (theme: Theme): Components<Theme> => {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "transparent",
          },
        },
      },
    },
  };
};

export default List;
