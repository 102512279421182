import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const Table = styled(
  forwardRef<BoxProps, any>((props, ref) => {
    return <Box ref={ref} {...props} />;
  })
  
)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "2.7rem auto min-content",
  gridTemplateAreas: `
    "header header header header"
    "body body body body"
    "footer footer footer footer"
  `,
  height: "50%",
  flex: 1,
}));

export default Table;
