import { Components, Theme } from "@mui/material/styles";

const Button = (theme: Theme): Components<Theme> => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "3rem",
          fontSize: "1.4rem",
          fontWeight: 400,
          textTransform: "unset",
        },
      },
      variants: [
        {
          props: {
            color: "agreeCardButton",
          },
          style: {},
        },
        {
          props: {
            color: "declineCardButton",
          },
          style: {},
        },
        {
          props: {
            color: "dialogButton",
          },
          style: {},
        },
      ],
    },
  };
};

export default Button;
