const ChevronDownIcon = ({
  className,
  mode,
}: {
  className: any;
  mode: "light" | "dark";
}) => {
  return (
    <svg
      className={className}
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0.666824C12 0.837488 11.9347 1.00815 11.8047 1.13815L6.47141 6.47141C6.21075 6.73207 5.78942 6.73207 5.52876 6.47141L0.195497 1.13815C-0.0651658 0.877488 -0.0651658 0.45616 0.195497 0.195497C0.45616 -0.0651658 0.877488 -0.0651658 1.13815 0.195497L6.00008 5.05743L10.862 0.195497C11.1227 -0.0651658 11.544 -0.0651658 11.8047 0.195497C11.9347 0.325495 12 0.49616 12 0.666824Z"
        fill={mode === "light" ? "#071224" : "#7C8A9E"}
      />
    </svg>
  );
};

export default ChevronDownIcon;
