import { Theme } from "@mui/material/styles";
import Button from "./Button";
import Tooltip from "./Tooltip";

import List from "./List";
import Menu from "./Menu";
import Form from "./Form";
import Input from "./Input";
import Pagination from "./Pagination";
import Typography from "./Typography";
import Select from "./Select";
import Cssbaseline from "./Cssbaseline";
import Checkbox from "./Checkbox";
import Calendar from "./Calendar";

const Overrides = (theme: Theme) => {
  const button = Button(theme);
  const tooltip = Tooltip(theme);
  const list = List(theme);
  const menu = Menu(theme);
  const form = Form(theme);
  const input = Input(theme);
  const pagination = Pagination(theme);
  const typography = Typography(theme);
  const select = Select(theme);
  const cssbaseline = Cssbaseline(theme);
  const checkbox = Checkbox(theme);
  const calendar = Calendar(theme);

  return Object.assign(
    button,
    tooltip,
    list,
    menu,
    form,
    input,
    pagination,
    typography,
    select,
    cssbaseline,
    checkbox,
    calendar
  );
};

export default Overrides;
