import { IconProps } from "./types";

const JobsIcon: React.FC<IconProps> = ({ isActive }) => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 3H1C0.447715 3 0 3.44771 0 4L0 16C0 16.5523 0.447715 17 1 17L21 17C21.5523 17 22 16.5523 22 16V4C22 3.44772 21.5523 3 21 3H15C15 4.10457 14.1046 5 13 5H9C7.89543 5 7 4.10457 7 3ZM2.01021 12.9999H4.5512H4.63312H7.17412C7.20144 12.7814 7.19051 12.295 6.92822 12.0983C6.58086 11.8378 6.19886 11.634 5.81672 11.4302L5.8167 11.4301L5.81668 11.4301L5.81667 11.4301L5.81665 11.4301L5.81664 11.4301C5.72253 11.3799 5.62841 11.3297 5.5348 11.2786C5.31805 11.1604 5.20694 10.8688 5.2889 10.7868C5.29841 10.7773 5.31122 10.7656 5.32644 10.7517C5.4425 10.6456 5.69873 10.4115 5.69873 10.0492V8.98359C5.69873 8.71387 5.51902 8.41849 5.2889 8.2459L5.24276 8.21101C5.05571 8.06896 4.97137 8.00492 4.55116 8.00027L4.55115 8L4.53816 8.00015L4.5 8L4.49996 8.00216C4.33595 8.01541 4.10287 8.09032 3.89542 8.2459C3.66531 8.41849 3.48559 8.71387 3.48559 8.98359V10.0492C3.48559 10.4115 3.74183 10.6456 3.85788 10.7517L3.8579 10.7517C3.87312 10.7656 3.88592 10.7773 3.89542 10.7868C3.97739 10.8688 3.86628 11.1604 3.64953 11.2786C3.55589 11.3297 3.46174 11.3799 3.36761 11.4302L3.36759 11.4302C2.98545 11.634 2.60346 11.8378 2.25611 12.0983C1.99382 12.295 1.98289 12.7814 2.01021 12.9999ZM10 8.5C10 8.22386 10.2239 8 10.5 8H18.5C18.7761 8 19 8.22386 19 8.5C19 8.77614 18.7761 9 18.5 9H10.5C10.2239 9 10 8.77614 10 8.5ZM10.5 10C10.2239 10 10 10.2239 10 10.5C10 10.7761 10.2239 11 10.5 11H18.5C18.7761 11 19 10.7761 19 10.5C19 10.2239 18.7761 10 18.5 10H10.5ZM10 12.5C10 12.2239 10.2239 12 10.5 12H18.5C18.7761 12 19 12.2239 19 12.5C19 12.7761 18.7761 13 18.5 13H10.5C10.2239 13 10 12.7761 10 12.5Z"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
      <rect
        x="8"
        y="1"
        width="6"
        height="3"
        rx="1"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
    </svg>
  );
};

export default JobsIcon;
