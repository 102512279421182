import { Components, Theme } from "@mui/material/styles";

const Tooltip = (theme: Theme): Components<Theme> => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.2rem",
          maxWidth: "50rem",
          maxHeight: "30rem",
          overflow: "auto",
        },
      },
    },
  };
};

export default Tooltip;
