import { borderValidation } from "./utils";

const emailBorderRegex = new RegExp("[a-zA-Z0-9]");
export const emailRegex =
  /^[a-zA-Z0-9._-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,63}))$/;

const validateEmail = (input: string) => {
  if (input.length < 2 || input.length > 200) return false;

  if (!borderValidation(input, emailBorderRegex)) {
    return false;
  }

  const email = input.split("@");
  if (email.length === 1) return false;

  const beforeAddressSymbol = email[0];
  const afterAddressSymbol = email[1];

  if (
    !borderValidation(beforeAddressSymbol, emailBorderRegex) ||
    !borderValidation(afterAddressSymbol, emailBorderRegex)
  ) {
    return false;
  }

  if (input.match(/[.!#$%&'*+/=?^_`{|}~-]{2}/i)) return false;

  return emailRegex.test(input);
};

export default validateEmail;
