import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import error from "@assets/error.png";

interface Error404Props {
  style?: React.CSSProperties | SxProps;
  [x: string]: any;
}

const Error404: React.FC<Error404Props> = ({ style = null, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          ...style,
        }}
      >
        <img src={error} alt="error" />

        <Box sx={{ textAlign: "center", marginTop: "5.2rem" }}>
          <h1
            style={{
              fontSize: "3.5rem",
              fontWeight: 700,
              marginBottom: 0,
            }}
          >
            Ошибка 404!
          </h1>
          <p
            style={{
              fontSize: "2.8rem",
              fontWeight: 400,
              marginTop: "2rem",
            }}
          >
            Страница не найдена
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default Error404;
