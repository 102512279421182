const ExitIcon = () => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6979 13.5951V10.5739H19.7403V7.55272H13.6979V4.53151L9.16611 7.93044V4.53151L3.1237 1.51033H15.2082V6.04211H16.7188V0H0.102783V19.6375L9.16638 24.1693V19.6375H16.7194V12.0845H15.2088V18.1269H9.16638V10.1965L13.6979 13.5951Z"
        fill="#7C8A9E"
      />
    </svg>
  );
};

export default ExitIcon;
