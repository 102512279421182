import NavLink from "../Navlink";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DriversIcon from "@assets/Sidebar/DriversIcon";

import PretripCardIcon from "@assets/Sidebar/PretripCardIcon";

const MedicalItem = () => {
  return (
    <>
      <List component="div" disablePadding>
        <NavLink to="/medical-cards">
          {(isActive) => (
            <ListItemButton
              disableRipple
              disableGutters
              sx={{ marginLeft: "3.8rem" }}
            >
              <ListItemIcon>
                <PretripCardIcon isActive={isActive} />
              </ListItemIcon>
              <ListItemText
                primary="Предрейсовый контроль"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "700",
                }}
              />
            </ListItemButton>
          )}
        </NavLink>
      </List>

      <List component="div" disablePadding>
        <NavLink to="/drivers">
          {(isActive) => (
            <ListItemButton
              disableRipple
              disableGutters
              sx={{ marginLeft: "3.8rem" }}
            >
              <ListItemIcon>
                <DriversIcon isActive={isActive} />
              </ListItemIcon>
              <ListItemText
                primary="Водители"
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: "700",
                }}
              />
            </ListItemButton>
          )}
        </NavLink>
      </List>
    </>
  );
};

export default MedicalItem;
