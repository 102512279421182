import { styled } from "@mui/material/styles";
import { Box, BoxProps } from "@mui/material";

const TableBody = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }) => ({
    gridArea: "body",
    display: "grid",
    overflowY: "scroll",
    position: "relative",
    gridAutoRows: "0.1fr",

    [theme.breakpoints.only("xl")]: {
      rowGap: "2rem",
      padding: "3rem 0rem",
    },
    [theme.breakpoints.only("lg")]: {
      rowGap: "1.5rem",
      padding: "2rem 0rem",
    },
    [theme.breakpoints.only("md")]: {
      rowGap: "1rem",
      padding: "1rem 0rem",
    },
    [theme.breakpoints.only("sm")]: {
      rowGap: "1rem",
      padding: "1rem 0rem",
    },
    [theme.breakpoints.only("xs")]: {
      rowGap: "1rem",
      padding: "1rem 0rem",
    },

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& > .MuiBox-root": {
      padding: "1.5rem 0",
    },
  })
);

export default TableBody;
