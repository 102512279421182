const ThemeIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 17V9.5V2C5.35786 2 2 5.35786 2 9.5C2 13.6421 5.35786 17 9.5 17ZM9.5 0C12.1077 0 14.47 1.05068 16.1867 2.75179C16.197 2.762 16.2073 2.77223 16.2175 2.78248C16.228 2.79294 16.2384 2.80342 16.2488 2.81391C17.9496 4.53053 19 6.89259 19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0Z"
        fill="#7C8A9E"
      />
    </svg>
  );
};

export default ThemeIcon;
