type IconProps = {
  mode: "light" | "dark";
};

const FilterIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill={mode === "light" ? "#E7F4FF" : "#A4C2FF"} />
      <path d="M10 13.5H30" stroke="#071224" stroke-width="1.5" />
      <path d="M10 20H30" stroke="#071224" stroke-width="1.5" />
      <path d="M10 26H30" stroke="#071224" stroke-width="1.5" />
      <circle
        cx="24"
        cy="14"
        r="2.25"
        fill={mode === "light" ? "#E7F4FF" : "#A4C2FF"}
        stroke="#071224"
        stroke-width="1.5"
      />
      <circle
        cx="16"
        cy="20"
        r="2.25"
        fill={mode === "light" ? "#E7F4FF" : "#A4C2FF"}
        stroke="#071224"
        stroke-width="1.5"
      />
      <circle
        cx="24"
        cy="26"
        r="2.25"
        fill={mode === "light" ? "#E7F4FF" : "#A4C2FF"}
        stroke="#071224"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default FilterIcon;
