type IconProps = {
  mode: "light" | "dark";
};

const CreateButtonIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill={mode === "light" ? "#E7F4FF" : "#A4C2FF"}
      />
      <path
        d="M12 20H28"
        stroke="#071224"
        stroke-width="1.33333"
        stroke-linecap="round"
      />
      <path
        d="M20 28V12"
        stroke="#071224"
        stroke-width="1.33333"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CreateButtonIcon;
