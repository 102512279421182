export enum Roles {
  Driver = "10",
  Medical = "20",
  Dispatcher = "30",
  Supervisor = "50",
  Admin = "100",
}
export enum TableInfo {
  Admin = "Admin",
  Supervisor = "Supervisor",
  DispatcherAdmin = "DispatcherAdminPanel",
  DispatcherOrg = "DispatcherOrgPanel",
  DriverAdmin = "DriverAdminPanel",
  DriverOrg = "DriverOrgPanel",
  MedicalAdmin = "MedicalAdminPanel",
  MedicalOrg = "MedicalOrgPanel",
}

export enum TranslatedRoles {
  "guest" = "Гость",
  "driver" = "Водитель",
  "medicalworker" = "Медик",
  "dispatcher" = "Диспетчер",
  "supervisor" = "Руководитель",
  "root" = "Администратор",
}
