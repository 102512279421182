import Grid, { GridProps } from "@mui/material/Grid";

import { styled } from "@mui/material/styles";

const ActionBarChipContainer = styled((props: GridProps) => (
  <Grid {...props} />
))(({ theme }) => ({
  display: "flex",
  flex: "wrap",
  
  [theme.breakpoints.only("xl")]: {
    gap: "3rem",
  },
  [theme.breakpoints.only("lg")]: {
    gap: "2rem",
  },
  [theme.breakpoints.only("md")]: {
    gap: "1rem",
  },
  [theme.breakpoints.only("sm")]: {
    gap: "1rem",
  },
  [theme.breakpoints.only("xs")]: {
    gap: "1rem",
  },

  "& > .MuiChip-root": {
    maxWidth: "35rem",
    background: theme.palette.actionBar.chip.bg,
    color: theme.palette.actionBar.chip.textColor,
    transition: "none",
  },
}));

export default ActionBarChipContainer;
