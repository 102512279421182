import { UsersErrors } from "@constants/notifications/errors";
import { notificationBuilder } from "@utils/notificationBuilder";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { TableInfo } from "@constants/roles";
import UserService from "@services/users/users_service";

import {
  UsersEntityList,
  AllUsersAdminPanelList,
  AddNewUserParamsAdminPanel,
  EditUserParamsAdminPanel,
  DriverUsersOrgPanelList,
  EditUserParamsOrgPanel,
  AddNewUserOrgPanel,
  JobsGetParams,
  JobsCreateParams,
  JobsEditParams,
  JobsDeleteParams,
  CompanyEditParams,
  CompainesList,
} from "@app-types/users.model";
import { UsersSuccess } from "@constants/notifications/success";
import { isAxiosError } from "axios";

const getUpdatedState = async (
  tableInfo: string,
  searchArguments: FoundationApp.SearchParams
) => {
  let updatedState = null;

  // TODO: переделать, чтобы этого небыло, а я напрямую обновлял стейт без рефетча, соответственно убираем tableInfo поле
  switch (tableInfo) {
    case TableInfo.Admin:
      updatedState = await UserService.getAdmins(searchArguments);
      break;
    case TableInfo.Supervisor:
      updatedState = await UserService.getSupervisors(searchArguments);
      break;
    case TableInfo.DriverOrg:
      updatedState = await UserService.getDriversOrgPanel(searchArguments);
      break;
    case TableInfo.DriverAdmin:
      updatedState = await UserService.getDriversAdminPanel(searchArguments);
      break;
    case TableInfo.MedicalOrg:
      updatedState = await UserService.getMedicalOrgPanel(searchArguments);
      break;
    case TableInfo.MedicalAdmin:
      updatedState = await UserService.getMedicalAdminPanel(searchArguments);
      break;
    case TableInfo.DispatcherAdmin:
      updatedState = await UserService.getDispatcherAdminPanel(searchArguments);
      break;
    case TableInfo.DispatcherOrg:
      updatedState = await UserService.getDispatcherOrgPanel(searchArguments);
      break;
  }

  return updatedState;
};

//-------------------------- Admin-------------------------

/**
 * Получение только Админов в Редактировании пользователей
 */
export const getAdmins = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getAdmins", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getAdmins(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- AdminPanel-------------------------

/**
 * Получение ВСЕХ пользователей
 */
export const getUsers = createAsyncThunk<
  AllUsersAdminPanelList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getUsers", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getUsers(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

export const editAdminPanelUser = createAsyncThunk<
  {
    updatedState: UsersEntityList | null;
    tableInfo: string;
  },
  {
    userArguments: EditUserParamsAdminPanel;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "profile/editAdminPanelUser",
  async ({ userArguments, searchArguments }, thunkAPI) => {
    try {
      const tableInfo = userArguments.tableInfo;
      await UserService.editAdminPanelUser(userArguments);

      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.EDIT_USERS, "success", thunkAPI);

      return {
        updatedState,
        tableInfo: userArguments.tableInfo,
      };
    } catch (error) {
      notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

      return thunkAPI.rejectWithValue(false);
    }
  }
);

/**
 * Добавление новых пользователей в АДМИНСКОЙ ПАНЕЛИ
 */
export const addNewUserAdminPanel = createAsyncThunk<
  {
    updatedState: UsersEntityList | DriverUsersOrgPanelList | null;
    tableInfo: string;
  },
  {
    userArguments: AddNewUserParamsAdminPanel;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "users/addNewUserAdminPanel",
  async ({ userArguments, searchArguments }, thunkAPI) => {
    try {
      const tableInfo = userArguments.tableInfo;
      await UserService.addNewUserAdminPanel(userArguments);

      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.ADD_NEW_USER, "success", thunkAPI);

      return {
        updatedState,
        tableInfo,
      };
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        if (status === 409 || status === 204) {
          notificationBuilder("Пользователь уже существует", "error", thunkAPI);
        } else {
          notificationBuilder(UsersErrors.ADD_NEW_USER, "error", thunkAPI);
        }
      }

      return thunkAPI.rejectWithValue(false);
    }
  }
);

/**
 * Удаление пользователя в АДМИНСКОЙ ПАНЕЛИ
 */
export const deleteUserAdminPanel = createAsyncThunk<
  {
    updatedState: UsersEntityList | DriverUsersOrgPanelList | null;
    tableInfo: string;
  },
  {
    userId: string;
    tableInfo: string;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "users/deleteUserAdminPanel",
  async ({ userId, tableInfo, searchArguments }, thunkAPI) => {
    try {
      await UserService.deleteUserAdminPanel(userId);
      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.DELETE_USER, "success", thunkAPI);

      return {
        updatedState,
        tableInfo,
      };
    } catch (error) {
      notificationBuilder(UsersErrors.DELETE_USER, "error", thunkAPI);

      return thunkAPI.rejectWithValue(false);
    }
  }
);

//-------------------------- Company -------------------------
/**
 * Получение списка компании (admin)
 */
export const getCompanies = createAsyncThunk<
  CompainesList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getCompanies", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getCompaines(searchArguments);

    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_COMPANIES, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Создание компании
 */
export const createCompany = createAsyncThunk<
  CompainesList,
  { value: string; searchArguments: FoundationApp.SearchParams },
  FoundationApp.AsyncThunkConfig<boolean>
>("users/createCompany", async ({ value, searchArguments }, thunkAPI) => {
  try {
    await UserService.createCompany(value);
    notificationBuilder(UsersSuccess.ADD_NEW_COMPANY, "success", thunkAPI);
    const data = await UserService.getCompaines(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.ADD_NEW_COMPANY, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Редактирование компании
 */
export const editCompany = createAsyncThunk<
  CompainesList,
  {
    companyData: CompanyEditParams;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>("users/editCompany", async ({ companyData, searchArguments }, thunkAPI) => {
  try {
    await UserService.editCompany(companyData);
    notificationBuilder(UsersSuccess.EDIT_COMPANY, "success", thunkAPI);
    const data = await UserService.getCompaines(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.EDIT_COMPANY, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Удаление компании
 */
export const deleteCompany = createAsyncThunk<
  CompainesList,
  { id: number; searchArguments: FoundationApp.SearchParams },
  FoundationApp.AsyncThunkConfig<boolean>
>("users/deleteCompany", async ({ id, searchArguments }, thunkAPI) => {
  try {
    await UserService.deleteCompany(id);
    notificationBuilder(UsersSuccess.DELETE_COMPANY, "success", thunkAPI);
    const data = await UserService.getCompaines(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.DELETE_COMPANY, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение списка компании (select list)
 */
export const getCompanyList = createAsyncThunk<
  FoundationApp.SelectItem[],
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getCompanyList", async (_, thunkAPI) => {
  try {
    const data = await UserService.getCompanyList();
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_COMPANY_LIST, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- JobTitle -------------------------
/**
 * Создание должности
 */

export const createJobTitle = createAsyncThunk<
  FoundationApp.SelectItem[],
  JobsCreateParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/createJobTitle", async (jobTitleData, thunkAPI) => {
  try {
    await UserService.createJobTitle(jobTitleData);
    const resp = await UserService.getJobsTitle(
      jobTitleData.role,
      jobTitleData.companyId
    );

    notificationBuilder(UsersSuccess.ADD_NEW_JOB, "success", thunkAPI);
    return resp;
  } catch (error) {
    notificationBuilder(UsersErrors.ADD_NEW_JOB, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Удаление должности
 */

export const deleteJobTitle = createAsyncThunk<
  FoundationApp.SelectItem[],
  JobsDeleteParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/deleteJobTitle", async (jobTitleData, thunkAPI) => {
  try {
    await UserService.deleteJobTitle(jobTitleData.id);
    const data = UserService.getJobsTitle(
      jobTitleData.role,
      jobTitleData.companyId
    );

    notificationBuilder(UsersSuccess.DELETE_JOB, "success", thunkAPI);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.DELETE_JOB, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Редактирование должности
 */
export const editJobTitle = createAsyncThunk<
  FoundationApp.SelectItem[],
  JobsEditParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/editJobTitle", async (jobTitleData, thunkAPI) => {
  try {
    await UserService.editJobTitle(jobTitleData);

    const data = UserService.getJobsTitle(
      jobTitleData.role,
      jobTitleData.companyId
    );
    notificationBuilder(UsersSuccess.EDIT_JOB, "success", thunkAPI);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.EDIT_JOB, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение списка должностей
 */

export const getJobsTitles = createAsyncThunk<
  FoundationApp.SelectItem[],
  JobsGetParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getJobsTitles", async ({ role, company }, thunkAPI) => {
  try {
    const data = await UserService.getJobsTitle(role, company);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_JOBS_LIST, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- Dispatcher-------------------------
/**
 * Получение списка диспетчеров вне админской учетки
 */
export const getDispatcherOrgPanel = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getDispatcherOrgPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getDispatcherOrgPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение списка диспетчеров в АДМИНСКОЙ ПАНЕЛИ
 */
export const getDispatcherAdminPanel = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getDispatcherAdminPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getDispatcherAdminPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});
//-------------------------- DriverOrgPanel-------------------------
/**
 * Получение списка водителей вне админской учетки
 */
export const getDriversOrgPanel = createAsyncThunk<
  DriverUsersOrgPanelList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getDriversOrgPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getDriversOrgPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение списка водителей в АДМИНСКОЙ ПАНЕЛИ
 */
export const getDriversAdminPanel = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getDriversAdminPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getDriversAdminPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- Medical -------------------------
/**
 * Получение списка медиков вне админской учетки
 */
export const getMedicalOrgPanel = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getMedicalOrgPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getMedicalOrgPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Получение списка медиков в АДМИНСКОЙ ПАНЕЛИ
 */
export const getMedicalAdminPanel = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getMedicalAdminPanel", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getMedicalAdminPanel(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- Role -------------------------
/**
 * Получение списка типов ролей
 */
export const getRoles = createAsyncThunk<
  FoundationApp.SelectItem[],
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getRoles", async (_, thunkAPI) => {
  try {
    const data = await UserService.getRoles();
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

//-------------------------- Supervisor -------------------------
/**
 * Получение списка руководителей в АДМИНСКОЙ ПАНЕЛИ
 */
export const getSupervisors = createAsyncThunk<
  UsersEntityList,
  FoundationApp.SearchParams,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getSupervisors", async (searchArguments, thunkAPI) => {
  try {
    const data = await UserService.getSupervisors(searchArguments);
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Редактирование пользователей Орг панели
 *
 * * Имеет одни и те же параметры и URL как для editProfile
 */
export const editOrgUsers = createAsyncThunk<
  {
    updatedState: UsersEntityList | DriverUsersOrgPanelList | null;
    tableInfo: string;
  },
  {
    userArguments: EditUserParamsOrgPanel;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "users/editOrgUsers",
  async ({ userArguments, searchArguments }, thunkAPI) => {
    try {
      const tableInfo = userArguments.tableInfo;
      await UserService.editOrgUsers(userArguments);

      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.EDIT_USERS, "success", thunkAPI);

      return {
        updatedState,
        tableInfo: userArguments.tableInfo,
      };
    } catch (error) {
      notificationBuilder(UsersErrors.EDIT_USERS, "error", thunkAPI);

      return thunkAPI.rejectWithValue(false);
    }
  }
);

/**
 * Удаление пользователя вне админской учетки
 */
export const deleteOrgUser = createAsyncThunk<
  {
    updatedState: UsersEntityList | DriverUsersOrgPanelList | null;
    tableInfo: string;
  },
  {
    userId: string;
    tableInfo: string;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "users/deleteOrgUser",
  async ({ userId, tableInfo, searchArguments }, thunkAPI) => {
    try {
      await UserService.deleteOrgUser(userId);
      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.DELETE_USER, "success", thunkAPI);

      return {
        updatedState,
        tableInfo: tableInfo,
      };
    } catch (error) {
      notificationBuilder(UsersErrors.DELETE_USER, "error", thunkAPI);

      return thunkAPI.rejectWithValue(false);
    }
  }
);

//-------------------------- Worker -------------------------
/**
 * Получение списка водителей (ФИО и id)
 */
export const getWorkers = createAsyncThunk<
  FoundationApp.SelectItem[],
  void,
  FoundationApp.AsyncThunkConfig<boolean>
>("users/getWorkers", async (_, thunkAPI) => {
  try {
    const data = await UserService.getWorkers();
    return data;
  } catch (error) {
    notificationBuilder(UsersErrors.GET_USERS, "error", thunkAPI);

    return thunkAPI.rejectWithValue(false);
  }
});

/**
 * Не используется на данный момент
 * Profile/register
 */
export const addNewUserOrgPanel = createAsyncThunk<
  {
    tableInfo: string;
    updatedState: DriverUsersOrgPanelList | UsersEntityList | null;
  },
  {
    userArguments: AddNewUserOrgPanel;
    searchArguments: FoundationApp.SearchParams;
  },
  FoundationApp.AsyncThunkConfig<boolean>
>(
  "users/addNewUserOrgPanel",
  async ({ userArguments, searchArguments }, thunkAPI) => {
    try {
      const tableInfo = userArguments.tableInfo;
      await UserService.addNewUserOrgPanel(userArguments);

      let updatedState = await getUpdatedState(tableInfo, searchArguments);

      notificationBuilder(UsersSuccess.ADD_NEW_USER, "success", thunkAPI);
      return {
        updatedState,
        tableInfo,
      };
    } catch (error) {
      // @ts-ignore
      if (error.message === "204") {
        notificationBuilder("Пользователь уже существует", "error", thunkAPI);
      }

      if (isAxiosError(error)) {
        const status = error.response?.status;
        if (status === 409) {
          notificationBuilder("Пользователь уже существует", "error", thunkAPI);
        } else {
          notificationBuilder(UsersErrors.ADD_NEW_USER, "error", thunkAPI);
        }
      }

      return thunkAPI.rejectWithValue(false);
    }
  }
);
