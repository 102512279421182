import { Components, Theme } from "@mui/material/styles";

const Cssbaseline = (theme: Theme): Components<Theme> => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: "2px",
        },
        "*::-webkit-scrollbar-track": {
          margin: "1.5rem 0",
          background: theme.palette.scrollbar.trackBg,
        },
        "*::-webkit-scrollbar-thumb": {
          cursor: "pointer",
          background: theme.palette.scrollbar.thumbBg,
        },
        "*::-webkit-scrollbar-thumb:hover": {
          background: theme.palette.scrollbar.thumbHover,
        },
      },
    },
  };
};

export default Cssbaseline;
