import { HubConnection } from "@microsoft/signalr";
import {
  updateComment,
  updateStatus,
  updateMedicalCards,
  updateDriverCards,
} from "@redux/slices/preTrip/preTripSlice";

import { MiddlewareAPI } from "@reduxjs/toolkit";

import {
  PretripCardList,
  PretripDriverCardList,
  PreTripUpdateComment,
  PreTripUpdateStatus,
} from "@app-types/preTrip.model";

export const preTripHubListener = (
  hubConnection: HubConnection,
  store: MiddlewareAPI,
  role: string
) => {
  hubConnection.on(
    "SendNewCardsNotice",
    (data: PretripCardList | PretripDriverCardList) => {
      if (role === "medicalworker" || role === "supervisor") {
        store.dispatch(updateMedicalCards(data as PretripCardList));
      } else if (role === "driver") {
        store.dispatch(updateDriverCards(data as PretripDriverCardList));
      }
    }
  );

  hubConnection.on("SendCommentChangeNotice", (data: PreTripUpdateComment) => {
    store.dispatch(
      updateComment({
        id: data.id,
        comment: data.comment,
        history: data.history,
      })
    );
  });

  hubConnection.on("SendStatusChangeNotice", (data: PreTripUpdateStatus) => {
    store.dispatch(
      updateStatus({ id: data.id, status: data.status, history: data.history })
    );
  });
};
