import { connectionStart, connectionStop } from "@redux/slices/hub/hubSlice";
import { useEffect } from "react";
import { useAppDispatch } from "@utils/hooks/redux-hooks";

export const useHub = (role?: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      role === "medicalworker" ||
      role === "supervisor" ||
      role === "driver"
    ) {
      dispatch(connectionStart());

      return () => {
        dispatch(connectionStop());
      };
    }
  }, [dispatch, role]);
};
