type IconProps = {
  mode: "light" | "dark";
};

const DeleteIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.92637 2.29048V0.168109C4.92637 0.0752648 5.00309 0 5.09772 0H8.01071V1.2398H6.36145C6.26681 1.2398 6.19009 1.31507 6.19009 1.40791V2.29048H8.01071V17H3.10791C2.8404 17 2.6176 16.7987 2.59559 16.5372L1.49933 3.50927H0.085676C0.0383585 3.50927 0 3.47164 0 3.42522V2.37454C0 2.32811 0.0383585 2.29048 0.085676 2.29048H4.92637Z"
        fill={mode === "light" ? "#071224" : "#F8FBFE"}
      />
      <path
        d="M11.0736 2.29048V0.168109C11.0736 0.0752648 10.9969 0 10.9023 0H8.01071V1.2398H9.63855C9.73319 1.2398 9.80991 1.31507 9.80991 1.40791V2.29048H8.01071V17H12.8921C13.1596 17 13.3824 16.7987 13.4044 16.5372L14.5007 3.50927H15.9143C15.9616 3.50927 16 3.47164 16 3.42522V2.37454C16 2.32811 15.9616 2.29048 15.9143 2.29048H11.0736Z"
        fill={mode === "light" ? "#071224" : "#F8FBFE"}
      />
      <path
        d="M10 14L10 5"
        stroke={mode === "light" ? "#F8FBFE" : "#071224"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
      <path
        d="M6 14L6 5"
        stroke={mode === "light" ? "#F8FBFE" : "#071224"}
        stroke-width="1.5"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default DeleteIcon;
