export enum AuthErrors {
  LOGIN = "Неправильный логин или пароль",
}
export enum DevicesErrors {
  GET_DEVICES = "Ошибка при получении устройств",
  ADD_NEW_DEVICE = "Ошибка при добавления устройств",
  EDIT_DEVICE = "Ошибка при редактировании устройства",
  DELETE_DEVICE = "Ошибка при удалении устройства",
  GET_DEVICE_TYPES = "Ошибка при получении типов устройств",
  ADD_NEW_DEVICE_TYPE = "Новый тип устройства загружен",
  EDIT_DEVICE_TYPE = "Тип устройства успешно отредактирован",
  DELETE_DEVICE_TYPE = "Ошибка при удалении типа устройства",
}
export enum EventsErrors {
  GET_EVENTS = "Ошибка при получении событий",
  GET_EVENTS_TYPE = "Ошибка при получении типов событий",
}
export enum PretripErrors {
  GET_PRETRIPLIST = "Ошибка при получении пред-рейсовых данных",
  GET_PRETRIPCARDS = "Ошибка при получении пред-рейсовых данных",
  GET_USERS_LAST_CARD_BY_ID = "Ошибка при получении последней анкеты пользователя",
  SEND_COMMENT = "Ошибка при отправке сообщения",
  SEND_STATUS = "Ошибка при обновлении статуса",
  GET_USER_STATISTIC = "Ошибка при получении статистики",
}
export enum ProfileErrors {
  GET_PROFILE_BY_ID = "Ошибка при получении профиля",
  GET_PROFILE_INFO = "Ошибка получения информации о профиле",
  GET_PROFILE_FOR_EDIT = "Ошибка при получении профиля для редактирования",
  EDIT_PROFILE = "Ошибка при редактирования профиля",
  ADD_PHOTO = "Ошибка при добавлении фотографии",
  DELETE_PHOTO = "Ошибка при удалении фотографии",
  TYPE_ERROR = "Неверный тип изображения",
  SIZE_ERROR = "Размер не должен превышать больше 2 Мб",
}
export enum UsersErrors {
  GET_USERS = "Ошибка при получении пользователей",
  EDIT_USERS = "Ошибка при редактировании пользователя",
  ADD_NEW_USER = "Ошибка при добавлении пользователя",
  DELETE_USER = "Ошибка при удалении пользователя",
  GET_COMPANIES = "Ошибка при получении списка всех компаний",
  ADD_NEW_COMPANY = "Ошибка при создании компании",
  EDIT_COMPANY = "Ошибка при редактировании компании",
  DELETE_COMPANY = "Ошибка при удалении компании",
  GET_COMPANY_LIST = "Ошибка при получении списка компании",
  GET_ROLES = "Ошибка при получении списка ролей",
  GET_JOBS_LIST = "Ошибка при получении должностей",
  ADD_NEW_JOB = "Ошибка при создании должности",
  EDIT_JOB = "Ошибка при обновлении должности",
  DELETE_JOB = "Ошибка при удалении должности",
}
export enum WaybillsErrors {
  GET_CARS = "Ошибка при получении списка автомобилей",
  GET_STATUSES = "Ошибка при получении списка статусов",
  GET_PRIORITIES = "Ошибка при получении списка приоритетов",
  EDIT_WAYBILL = "Ошибка при редактировании путевого листа",
  CREATE_WAYBILL = "Ошибка при создании путевого листа",
  GET_WAYBILL_FOR_EDIT = "Ошибка при получении путевого листа для редактирования",
  DELETE_WAYBILL = "Ошибка при удалении путевого листа",
  ACCEPT_WAYBILL = "Ошибка при старте задачи",
  CANCEL_WAYBILL = "Ошибка при отмене задачи",
  FINISH_WAYBILL = "Ошибка при завершении задачи",
  FINISH_WITH_REMARK_WAYBILL = "Ошибка при завершении задачи с ремаркой",
  ASSIGN_TO_DRIVER_WAYBILL = "Ошибка при привязке задачи к водителю",
  UNASSIGN_TO_DRVIER_WAYBILL = "Ошибка при отмене привязки задачи к водителю",
  ABNORMAL_SITUATION_WAYBILL = "Ошибка завершении задачи при нештатной ситуации",
  GET_TASKS_FOR_COMPANY = "Ошибка получения задач для компании",
  GET_TASKS_FOR_DRIVER = "Ошибка получении задачи для водителя",
}
