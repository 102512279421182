import React from "react";

const ChevronDownIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9863 8L10.9863 14L4.98633 8"
        stroke="#7C8A9E"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ChevronDownIcon;
