import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type TableRowProps = {
  rows: FoundationApp.TableRow[];
} & BoxProps;

const StyledTableRow = styled((props: TableRowProps) => <Box {...props} />)(
  ({ theme, rows }) => {
    let gridRowsWidth = "";
    let rowStyles: { [x: string]: any } = {};

    for (const item of rows) {
      if (item.hidden) {
        continue;
      }

      gridRowsWidth += `[${item.gridArea}] ${item.width ?? "1fr"} `;
      rowStyles[`#${item.gridArea}`] = {
        justifyContent: item.align,
      };
    }

    return {
      display: "grid",
      gridTemplateColumns: gridRowsWidth,
      borderRadius: "1rem",
      background: theme.palette.table.row.bg,
      gap: "5rem",
      ...rowStyles,

      "& > .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        paddingLeft: "1rem",
      },

      "&:hover": {
        background: theme.palette.table.row.hover.bg,
        cursor: "pointer",

        "& .MuiChip-root": {
          cursor: "pointer",
          background: "black",
          color: theme.palette.table.row.hover.chipTextColor,
        },
      },
    };
  }
);

export default StyledTableRow;
