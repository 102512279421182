import { useRef, useEffect, useState, ReactNode } from "react";
import { Tooltip } from "@mui/material";

interface OverflowTooltipProps {
  children: ReactNode;
  title: string;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
}

const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  children,
  title,
  placement,
  ...props
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);

  const textElementRef = useRef<HTMLDivElement>(null);

  const checkOverflow = () => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <Tooltip
      title={title}
      placement={placement}
      disableHoverListener={!isOverflowed}
    >
      <div
        ref={textElementRef}
        style={{
          // whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: isOverflowed ? "pointer" : "unset",
          ...props,
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;
