import { Components, Theme } from "@mui/material/styles";

const Pagination = (theme: Theme): Components<Theme> => {
  return {
    MuiPagination: {
      styleOverrides: {
        ul: {
          "& > li:not(:first-child)": {
            marginLeft: "3rem",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          ...theme.typography.number,
        },
      },
    },
  };
};

export default Pagination;
