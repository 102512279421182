import { useContext } from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText, PaletteMode } from "@mui/material";

import { Dialog } from "@components";
import List from "@mui/material/List";

// NAVLINKS BY ROLES
import {
  RootItem,
  SupervisorItem,
  MedicalItem,
  DispatcherItem,
  DriverItem,
  SettingsItem,
} from "./ListItems";

import { TokenInMemoryContext } from "@services/auth/TokenInMemoryContext";
import ExitIcon from "@assets/Sidebar/ExitIcon";
import ThemeIcon from "@assets/Sidebar/ThemeIcon";
import { useSettings } from "@utils/hooks/useSettings";

const DropDownList = ({ userRole }: { userRole: string; email: string }) => {
  const { logoutHandler } = useContext(TokenInMemoryContext);
  const { settings, saveSettings } = useSettings();

  const handleModeChange = (mode: PaletteMode) => {
    saveSettings({ ...settings, mode });
  };

  const handleModeToggle = () => {
    if (settings.mode === "light") {
      handleModeChange("dark");
    } else {
      handleModeChange("light");
    }
  };

  return (
    <>
      <List
        component="div"
        aria-labelledby="nested-list-subheader"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        <SettingsItem />

        {userRole === "root" && <RootItem />}
        {userRole === "medicalworker" && <MedicalItem />}
        {userRole === "supervisor" && <SupervisorItem />}
        {userRole === "driver" && <DriverItem />}
        {userRole === "dispatcher" && <DispatcherItem />}

        <Box sx={{ marginTop: "auto" }}>
          <ListItemButton
            onClick={handleModeToggle}
            disableRipple
            disableGutters
            sx={{ marginLeft: "4.5rem" }}
          >
            <ListItemIcon>
              <ThemeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Сменить тему"
              primaryTypographyProps={{
                variant: "body2",
                color: "#7C8A9E",
                fontWeight: "700",
              }}
            />
          </ListItemButton>

          <Dialog
            title={"Точно выйти?"}
            actionHandler={() => {
              logoutHandler();
            }}
          >
            {(handleClickOpen) => {
              return (
                <ListItemButton
                  onClick={handleClickOpen}
                  disableRipple
                  disableGutters
                  sx={{ marginLeft: "4.5rem" }}
                >
                  <ListItemIcon>
                    <ExitIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Выйти"
                    primaryTypographyProps={{
                      variant: "body2",
                      color: "#7C8A9E",
                      fontWeight: "700",
                    }}
                  />
                </ListItemButton>
              );
            }}
          </Dialog>
        </Box>
      </List>
    </>
  );
};

export default DropDownList;
