export const borderValidation = (input: string, regex: RegExp) => {
  const borderFirst = input.at(0);
  const borderLast = input.at(input.length - 1);
  if (!regex.test(borderFirst!) || !regex.test(borderLast!)) {
    return false;
  }

  return true;
};

export const checkNestedBrackets = (value: string) => {
  let depth = 0;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === "(") {
      depth++;
      // Вложенные скобки запрещены
      if (depth > 1) {
        return false;
      }
    } else if (value[i] === ")") {
      depth--;
      if (depth < 0) {
        return false;
      }
    }
  }

  return depth === 0; // Если сбалансированы
};

export const checkInValidQuotes = (value: string) => {
  let qutoesCount = 0;

  for (let i = 0; i < value.length; i++) {
    if (value[i] === '"') qutoesCount++;
  }

  return qutoesCount % 2 === 0;
};
