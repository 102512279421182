import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

type TableHeaderProps = {
  rows: FoundationApp.TableHeaderProps;
} & BoxProps;

const StyledTableHeader = styled((props: TableHeaderProps) => (
  <Box {...props} />
))(({ theme, rows }) => {
  let gridHeadersAreaNames = "";
  let gridHeadersWidth = "";
  let headerStyles: { [x: string]: any } = {};

  for (const key in rows) {
    if (rows[key].hidden) {
      continue;
    }

    gridHeadersAreaNames += `${rows[key].gridArea} `;
    gridHeadersWidth += `[${rows[key].gridArea}] ${
      rows[key].width ?? "1fr"
    } `;
    headerStyles[`#${rows[key].gridArea}`] = {
      justifyContent: rows[key].headerAlign,
    };
  }

  return {
    gridArea: "header",
    display: "grid",
    gridTemplateColumns: gridHeadersWidth,
    gridTemplateAreas: `"${gridHeadersAreaNames}"`,
    borderBottom: `3px solid ${theme.palette.table.header.border}`,
    paddingBottom: ".5rem",
    alignItems: "end",
    gap: "5rem",
    ...headerStyles,

    "& > .MuiBox-root": {
      display: "flex",
      alignItems: "center",
      paddingLeft: "1rem",
    },
  };
});

export default StyledTableHeader;
