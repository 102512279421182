import { useAuth } from "@utils/hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoute = () => {
  const user = useAuth();
  const location = useLocation();

  return user ? (
    <Navigate to={"/settings"} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
