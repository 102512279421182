import { setMessage } from "@redux/slices/toast/toastSlice";

type MessageType = FoundationApp.Notification["type"];

export const notificationBuilder = (
  message = "",
  type: MessageType,
  API?: any
) => {
  API.dispatch(
    setMessage({
      message: `${message}`,
      type,
    })
  );
};
