import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/store";
import TokenInMemory from "@services/auth/TokenInMemoryContext";
import { AxiosInterceptor } from "@services/auth/AxiosInterceptor";

const persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <TokenInMemory>
        <AxiosInterceptor>
          <Router>
            <App />
          </Router>
        </AxiosInterceptor>
      </TokenInMemory>
    </PersistGate>
  </Provider>
);
