import Box from "@mui/material/Box";
import error from "@assets/error.png";

interface ErrorBoundaryUIProps {
  style?: React.CSSProperties;
  [x: string]: any;
}

const ErrorBoundaryUI: React.FC<ErrorBoundaryUIProps> = ({
  style = null,
  ...props
}) => {
  return (
    <Box
      {...props}
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          ...style,
        }}
      >
        <img src={error} alt="error" />

        <Box sx={{ textAlign: "center", marginTop: "5.2rem" }}>
          <h1
            style={{
              fontSize: "3.5rem",
              fontWeight: 700,
              marginBottom: 0,
            }}
          >
            Ошибка!
          </h1>
          <p
            style={{
              fontSize: "2.8rem",
              fontWeight: 400,
              marginTop: "2rem",
            }}
          >
            Что-то пошло не так, попробуйте перезагрузить страницу
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorBoundaryUI;
