import { IconProps } from "./types";

const ControlIcon: React.FC<IconProps> = ({ isActive }) => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7402 14.4537C14.8942 14.5397 15.0481 14.6257 15.2021 14.6257C15.356 14.6257 15.51 14.5397 15.6639 14.4537C15.8949 14.2816 20.5905 10.3241 20.5905 6.02236C20.5905 2.66705 18.2042 0 15.2021 0C12.2 0 9.81365 2.66705 9.81365 6.02236C9.81365 10.238 14.5093 14.2816 14.7402 14.4537ZM16.7416 6.15878C16.7416 7.00905 16.0524 7.69833 15.2021 7.69833C14.3518 7.69833 13.6625 7.00905 13.6625 6.15878C13.6625 5.30851 14.3518 4.61923 15.2021 4.61923C16.0524 4.61923 16.7416 5.30851 16.7416 6.15878ZM25.1474 11.2803C25.2299 11.5279 25.2299 11.7755 25.1474 11.9406C25.1474 12.0231 25.0648 12.1057 24.9823 12.1882L19.6836 18.8738C19.6423 18.915 19.601 18.9357 19.5598 18.9563C19.5185 18.9769 19.4772 18.9976 19.4359 19.0389C19.3534 19.1214 19.1883 19.1214 19.1058 19.1214L10.4843 19.0389C10.2367 19.0389 10.0716 18.9563 9.90655 18.7912L6.68759 16.935H1.2401C0.909948 16.935 0.662334 16.7699 0.497259 16.4398C0.332184 16.1096 0.414721 15.7795 0.662334 15.5319L6.10982 11.0422C6.15109 11.0009 6.19236 10.9803 6.23363 10.9597C6.2749 10.939 6.31617 10.9184 6.35744 10.8771C6.43998 10.7946 6.60505 10.7946 6.68759 10.7946L9.8407 10.7944C10.1111 11.3621 10.3969 11.7205 10.6864 12.0837C10.7802 12.2013 10.8743 12.3194 10.9684 12.4452L7.01774 12.4453L3.99078 15.2842H7.01774C7.26535 15.2842 7.43043 15.3668 7.5955 15.5319L10.8145 17.3881L18.2813 17.3202L22.4236 12.4358L19.8208 12.4629C20.0008 12.219 20.1437 12.0029 20.3015 11.7643C20.4811 11.4926 20.6801 11.1916 20.9755 10.7866L24.4045 10.7851C24.4871 10.7851 24.6521 10.7851 24.7347 10.8676C24.8997 10.9502 25.0648 11.1152 25.1474 11.2803Z"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
    </svg>
  );
};

export default ControlIcon;
