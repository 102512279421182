type IconProps = {
  mode: "light" | "dark";
};

const BirthdayIcon: React.FC<IconProps> = ({ mode }) => {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.34282 8.7207C5.74093 8.7207 4.42871 10.0981 4.42871 11.7793V18.7647L4.4296 18.7656C4.42871 18.9603 4.50132 19.146 4.63238 19.2839C4.76255 19.4218 4.93966 19.4993 5.12473 19.5002H17.877C18.0629 19.5011 18.2409 19.4236 18.3729 19.2857C18.5039 19.1478 18.5774 18.9603 18.5774 18.7656V11.7802C18.5774 10.099 17.2615 8.72161 15.6597 8.72161L7.34282 8.7207ZM7.34282 10.1856H15.6587C16.5062 10.1856 17.1809 10.8896 17.1809 11.7793V18.0342H5.82417V11.7793C5.82417 10.8896 6.4945 10.1856 7.34282 10.1856Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M3.71106 18.0449C3.52334 18.044 3.34269 18.1188 3.20899 18.2541C3.07528 18.3884 3.00001 18.5723 3.00001 18.7634C2.99912 18.9554 3.07351 19.1402 3.20722 19.2763C3.34093 19.4124 3.52245 19.4881 3.71108 19.4872H19.2942C19.482 19.4863 19.6626 19.4097 19.7946 19.2745C19.9274 19.1384 20.0009 18.9545 20 18.7634C19.9991 18.3667 19.6839 18.0458 19.2942 18.0449L3.71106 18.0449Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M11.5031 7.22755C11.3153 7.22665 11.1347 7.30146 11.001 7.43668C10.8682 7.57099 10.7929 7.75489 10.792 7.94599V9.45228C10.7911 9.64428 10.8655 9.82908 10.9992 9.96521C11.1329 10.1013 11.3144 10.177 11.5031 10.1761C11.6908 10.1761 11.8714 10.0986 12.0034 9.9634C12.1362 9.82729 12.2097 9.6434 12.2088 9.4523V7.946C12.2079 7.54936 11.8927 7.22845 11.5031 7.22755Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M11.5004 3.5C11.5004 3.5 10.6504 4.34211 10.6504 5.18421C10.6504 5.64948 11.0308 6.02632 11.5004 6.02632C11.97 6.02632 12.3504 5.64948 12.3504 5.18421C12.3504 4.34211 11.5004 3.5 11.5004 3.5Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M15.0429 7.22755C14.6533 7.22846 14.3381 7.54936 14.3372 7.946V9.4523C14.3363 9.6434 14.4107 9.8273 14.5426 9.9634C14.6754 10.0995 14.8552 10.1761 15.0429 10.1761C15.2315 10.177 15.4131 10.1013 15.5468 9.96521C15.6805 9.82909 15.7549 9.6443 15.754 9.45228V7.94599C15.7531 7.75488 15.6778 7.57099 15.545 7.43668C15.4113 7.30146 15.2306 7.22665 15.0429 7.22755Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M14.9 3.5C14.9 3.5 14.05 4.34211 14.05 5.18421C14.05 5.64948 14.4304 6.02632 14.9 6.02632C15.3697 6.02632 15.75 5.64948 15.75 5.18421C15.75 4.34211 14.9 3.5 14.9 3.5Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M7.95698 7.22755C7.56735 7.22846 7.25211 7.54936 7.25123 7.946V9.4523C7.25034 9.6434 7.32473 9.8273 7.45667 9.9634C7.58949 10.0986 7.76925 10.1761 7.95697 10.1761C8.14559 10.177 8.32711 10.1013 8.46084 9.96521C8.59455 9.82909 8.66893 9.6443 8.66805 9.45228V7.94599C8.66805 7.75488 8.59189 7.57099 8.45907 7.43668C8.32535 7.30146 8.14471 7.22665 7.95698 7.22755Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M8.1 3.5C8.1 3.5 7.25 4.34211 7.25 5.18421C7.25 5.64948 7.63036 6.02632 8.1 6.02632C8.56964 6.02632 8.95 5.64948 8.95 5.18421C8.95 4.34211 8.1 3.5 8.1 3.5Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
      <path
        d="M13.2108 13.3514C12.9319 13.4758 12.6291 13.5623 12.3581 13.6173C11.9632 13.6984 11.0378 13.6993 10.6376 13.6227C10.3595 13.5695 10.0514 13.4866 9.7795 13.3676C9.09766 13.0683 8.25376 12.9646 7.48879 13.3892C6.8167 13.7624 5.90549 14.0283 5.44415 13.794L5.44504 13.7931C5.27679 13.7074 5.08197 13.693 4.90311 13.7543C4.72424 13.8147 4.57725 13.9463 4.49312 14.1176C4.32045 14.4718 4.46036 14.9018 4.80747 15.0812C6.04541 15.7113 7.34097 15.1128 8.16453 14.6557C8.36732 14.543 8.8933 14.5494 9.22096 14.6927C9.62297 14.8694 10.0241 14.9712 10.3766 15.0388C11.0602 15.1695 11.9457 15.1695 12.6355 15.028C12.9808 14.9577 13.3758 14.8486 13.7751 14.671C14.0806 14.5358 14.6226 14.5421 14.8369 14.6602C15.6613 15.1173 16.9577 15.7104 18.1939 15.0812H18.1948C18.5428 14.9036 18.6854 14.4736 18.5136 14.1176C18.4303 13.9463 18.2825 13.8147 18.1036 13.7543C17.9256 13.693 17.7299 13.7074 17.5617 13.793C17.1012 14.0274 16.1891 13.766 15.517 13.3937C14.7608 12.9745 13.9107 13.0403 13.2111 13.3513L13.2108 13.3514Z"
        fill={mode === "light" ? "#071224" : "#93A0B1"}
      />
    </svg>
  );
};

export default BirthdayIcon;
