import { RootState } from "@redux/store";

const selectProfileInfo = (state: RootState) =>
  state?.profile?.profileInfo || null;
const selectProfileById = (state: RootState) =>
  state?.profile?.profileById || null;
const selectProfileForEdit = (state: RootState) =>
  state?.profile?.profileForEdit || null;
const selectProfileLoadingStatus = (state: RootState) =>
  state?.profile?.loadingStatus;

export {
  selectProfileInfo,
  selectProfileById,
  selectProfileForEdit,
  selectProfileLoadingStatus,
};
