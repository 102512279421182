import { IconProps } from "./types";

const PretripCardIcon: React.FC<IconProps> = ({ isActive }) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.48464 8.65969L8.69168 15.8722C8.86197 16.0426 9.13805 16.0426 9.30833 15.8722L16.5154 8.65969C18.4949 6.67867 18.4949 3.4668 16.5154 1.48577C14.5358 -0.495256 11.3264 -0.495256 9.34687 1.48577C9.07246 1.76038 9 1.88086 9 1.88086C9 1.88086 8.78201 1.61474 8.65313 1.48577C6.67361 -0.495256 3.46417 -0.495256 1.48464 1.48577C-0.494881 3.4668 -0.494881 6.67867 1.48464 8.65969Z"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
    </svg>
  );
};

export default PretripCardIcon;
