import { Components, Theme } from "@mui/material/styles";

const Menu = (theme: Theme): Components<Theme> => {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: ".2rem",
          padding: "1.5rem 2rem",
          maxWidth: "100px !important",
          maxHeight: "30rem !important",
          borderRadius: "0 0 1rem 1rem",
        },
        // list: {
        //   paddingRight: "1rem",
        //   scrollbarWidth: "thin",
        //   scrollbarColor: `${theme.palette.menu.scrollbarColor} transparent`,
        // },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: theme.palette.menu.textColor,

          "&:hover": {
            borderRadius: "2.5rem",
            background: theme.palette.menu.hover,
          },

          "&:not(:last-child)": {
            marginBottom: "1.5rem",
          },

          "&.Mui-selected": {
            borderRadius: "2.5rem",
            background: theme.palette.menu.selected,
          },
        },
      },
    },
  };
};

export default Menu;
