import { createSlice } from "@reduxjs/toolkit";

export interface HubState {
  isConnected: boolean;
}

const initialState: HubState = {
  isConnected: false,
};

const hubSlice = createSlice({
  name: "hub",
  initialState,
  reducers: {
    connectionStart: (state) => {
      state.isConnected = true;
    },
    connectionStop: (state) => {
      state.isConnected = false;
    },
  },
});

const { reducer, actions } = hubSlice;
export const { connectionStart, connectionStop } = actions;

export default reducer;
