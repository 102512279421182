import DropDownList from "./components/DropDownList";

import { useAuth } from "@utils/hooks/useAuth";

import KamazLogo from "@assets/Sidebar/KamazLogoIcon.png";
import { Box } from "@mui/material";

const SideBar = () => {
  const { role, email } = useAuth();
  return (
    <>
      {role && (
        <>
          <Box
            sx={{
              width: "4.7rem",
              height: "7rem",
              marginBottom: "5.5rem",
              margin: "0 0 5.5rem 2.2rem",
            }}
          >
            <img src={KamazLogo} alt="logo" />
          </Box>
          <DropDownList userRole={role} email={email!} />
        </>
      )}
    </>
  );
};

export default SideBar;
