export const colors = {
  black: {
    50: "#20252E",
    100: "#1F242E",
    200: "#071224",
    300: "#050C19",
    400: "#171C26",
    500: "#000310",
    600: "#000",
  },
  gray: {
    50: "#E6EEF9",
    100: "#F8FBFE",
    200: "#CFD1D6",
    300: "#BDCADB",
    400: "#93A0B1",
    500: "#5A636E",
    600: "#3D424B",
    700: "#353F51",
    800: "#2B313C",
    900: "#CCD1D5",
    1000: "#F7F7F7",
  },
  blue: {
    50: "#E7F4FF",
    100: "#F6FBFF",
    200: "#A4C2FF",
    300: "#4C7CF8",
    400: "#4C7CF84D",
  },
  green: {
    50: "#EAFCED",
    100: "#ADFFAD",
    200: "#72EA7E",
  },
  red: {
    50: "#FDF1EE",
    100: "#FF4D4D",
    200: "#FF4D4D4D",
  },

  white: "#FFFFFF",
};
