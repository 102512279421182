import { useState } from "react";

import {
  TextField,
  IconButton,
  Tooltip,
  styled,
  InputAdornment,
  StandardTextFieldProps,
  useTheme,
} from "@mui/material";

import search from "@assets/search.png";
import ClearIcon from "@assets/IconsSVG/ClearIcon";

type SearchTextfieldProps = {
  changeHandler: (value: string) => void;
} & StandardTextFieldProps;

const SearchTextfield = styled(
  ({ changeHandler, ...props }: SearchTextfieldProps) => {
    const [value, setValue] = useState("");
    const theme = useTheme();

    return (
      <TextField
        autoComplete="off"
        id="standard-search"
        type="text"
        variant="standard"
        fullWidth
        onChange={(e) => {
          changeHandler(e.target.value);
          setValue(e.target.value);
        }}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 3 }}>
              <img src={search} alt="search" />
            </InputAdornment>
          ),
          endAdornment: value && (
            <Tooltip title="Очистить поле">
              <IconButton
                disableRipple
                aria-label="clear button"
                onClick={() => {
                  changeHandler("");
                  setValue("");
                }}
              >
                <ClearIcon mode={theme.palette.mode} />
              </IconButton>
            </Tooltip>
          ),
          disableUnderline: true,
        }}
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        {...props}
      />
    );
  }
)(({ theme }) => ({
  borderRadius: "3rem",
  padding: "0.5rem 2rem",
  background: theme.palette.searchTextField.bg,
  color: theme.palette.searchTextField.textColor,
  border: 0,
  "& fieldset": { border: "none" },
  "& .MuiInputBase-root": {
    border: 0,
  },
}));

export default SearchTextfield;
