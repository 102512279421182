import { TokenInfo } from "@app-types/auth.model";
import Cookies from "js-cookie";

class TokenService {
  // getRefreshToken() {
  //   return Cookies.get("refreshToken");
  // }

  // updateRefreshToken(tokenInfo: any) {
  //   let refresh_token = tokenInfo.refresh_token.toString();

  //   Cookies.set("refreshToken", refresh_token, {
  //     expires: 86000,
  //     secure: true,
  //     sameSite: "Strict",
  //   });
  // }

  // setRefreshToken(tokenInfo: TokenInfo) {
  //   let refresh_token = tokenInfo.refresh_token.toString();

  //   Cookies.set("refreshToken", refresh_token, {
  //     expires: 86000,
  //     secure: true,
  //     sameSite: "Strict",
  //   });
  // }

  // removeRefreshToken() {
  //   Cookies.remove("refreshToken");
  // }

  setSession() {
    Cookies.set("session", new Date().toDateString());
  }
  
  getSession() {
    return Cookies.get("session");
  }
  
  removeSession() {
    Cookies.remove("session");
  }
}

export default new TokenService();
