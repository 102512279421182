import { deepmerge } from "@mui/utils";

import palette from "./palette";
import { Settings } from "./context/themeContext";
import { ThemeOptions } from "@mui/material";

const themeOptions = (settings: Settings): ThemeOptions => {
  const { mode } = settings;

  const themeConfig = {
    palette: palette(mode),
    typography: {
      htmlFontSize: 10,
      fontSize: 12.25,
      fontFamily: `"Manrope", sans-serif`,
    },
  };

  return deepmerge(themeConfig, {});
};

export default themeOptions;
