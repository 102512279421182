export enum DevicesSuccess {
  ADD_NEW_DEVICE = "Новое устройство загружено",
  EDIT_DEVICE = "Устройство отредактировано",
  DELETE_DEVICE = "Устройство удалено",
  ADD_NEW_DEVICE_TYPE = "Новый тип устройства загружен",
  EDIT_DEVICE_TYPE = "Тип устройства успешно отредактирован",
  DELETE_DEVICE_TYPE = "Тип устройства удален",
}
export enum PretripSuccess {
  SEND_COMMENT = "Сообщение отправлено",
  SEND_STATUS_TRUE = "Рейс одобрен",
  SEND_STATUS_FALSE = "Рейс запрещен",
}
export enum ProfileSuccess {
  EDIT_PROFILE = "Настройки пользователя обновлены",
  ADD_PHOTO = "Фотография успешно загружена",
  DELETE_PHOTO = "Фотография успешно удалена",
}
export enum UsersSuccess {
  EDIT_USERS = "Настройки пользователя обновлены",
  ADD_NEW_USER = "Пользователь добавлен",
  DELETE_USER = "Пользователь удален",
  ADD_NEW_JOB = "Должность добавлена",
  EDIT_JOB = "Должность обновлена",
  DELETE_JOB = "Должность удалена",
  ADD_NEW_COMPANY = "Компания добавлена",
  EDIT_COMPANY = "Компания обновлена",
  DELETE_COMPANY = "Компания удалена",
}
export enum WaybillsSuccess {
  EDIT_WAYBILL = "Задание изменено",
  CREATE_WAYBILL = "Задание создано",
  DELETE_WAYBILL = "Задача удалена",
  ACCEPT_WAYBILL = "Задача начата",
  CANCEL_WAYBILL = "Задача отменена",
  FINISH_WAYBILL = "Задача завершена",
  FINISH_WITH_REMARK_WAYBILL = "Задача завершена c ремаркой",
  ASSIGN_TO_DRIVER_WAYBILL = "Задача назначена водителю",
  UNASSIGN_TO_DRVIER_WAYBILL = "Привязка к водителю отменена",
  ABNORMAL_SITUATION_WAYBILL = "Оповещено о проблемной ситуации",
}
