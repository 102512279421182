import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@utils/hooks/useAuth";
import { Suspense } from "react";
import { Spinner } from "@components";
import TokenService from "@services/auth/TokenService";
import RootLayout from "@layout/RootLayout";

type RequireAuthProps = {
  allowedRoles: string[];
};

const PrivateRoute: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
  const user = useAuth();
  const session = TokenService.getSession();
  const location = useLocation();

  return user && allowedRoles?.includes(user.role) ? (
    <RootLayout>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </RootLayout>
  ) : !session ? (
    <Navigate to={"/"} state={{ from: location }} replace />
  ) : user && session ? (
    <Navigate to={"/settings"} state={{ from: location }} replace />
  ) : (
    <Spinner />
  );
};

export default PrivateRoute;
