import { IconProps } from "./types";

const CompaniesIcon: React.FC<IconProps> = ({ isActive }) => {
  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7"
        width="6"
        height="13"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
      <rect
        x="15"
        y="5"
        width="5"
        height="8"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
      <rect
        y="5"
        width="5"
        height="8"
        fill={isActive ? "#E7F4FF" : "#7C8A9E"}
      />
    </svg>
  );
};

export default CompaniesIcon;
