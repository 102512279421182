import { Components, Theme } from "@mui/material/styles";

const Select = (theme: Theme): Components<Theme> => {
  return {
    MuiSelect: {
      styleOverrides: {
        icon: {
          // top: "calc(50%)",
          right: "1.5rem",
        },
      },
    },
  };
};

export default Select;
