import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { SxProps, useTheme } from "@mui/material";

interface IAlertDialog {
  children: (cb: (e: React.MouseEvent<HTMLElement>) => void) => React.ReactNode;
  title: string;
  actionHandler: () => void;
  style?: React.CSSProperties | SxProps;
  [x: string]: any;
}

const AlertDialog: React.FC<IAlertDialog> = ({
  children,
  title,
  actionHandler,
  style = null,
  ...props
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const agreeHandler = () => {
    setOpen(false);
    actionHandler();
  };

  return (
    <>
      {children(handleClickOpen)}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          sx: {
            background: theme.palette.background.paper,
            borderRadius: "1rem"
          },
        }}
        {...props}
      >
        <DialogTitle fontSize="2.8rem" id="alert-dialog-title" align="center">
          {title}
        </DialogTitle>

        <DialogActions
          sx={{ justifyContent: "space-evenly", padding: "2rem 12rem" }}
        >
          <Button
            sx={{
              fontSize: "2rem",
              mr: 5,
            }}
            disableElevation
            disableRipple
            color="dialogButton"
            fullWidth
            variant="contained"
            onClick={agreeHandler}
            autoFocus
            type="submit"
          >
            Да
          </Button>
          <Button
            fullWidth
            disableElevation
            disableRipple
            color="dialogButton"
            variant="contained"
            sx={{
              fontSize: "2rem",
              ml: 5,
            }}
            onClick={handleClose}
          >
            Нет
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
