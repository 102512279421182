const ChevronLeftIcon: React.FC<FoundationApp.IconSVG> = ({ color }) => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1L2 7L8 13" stroke={color} stroke-width="1.5" />
    </svg>
  );
};

export default ChevronLeftIcon;
