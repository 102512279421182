const ChevronRightIcon: React.FC<FoundationApp.IconSVG> = ({ className, color }) => {
  return (
    <svg
      className={className}
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7L1 13" stroke={color} stroke-width="1.5" />
    </svg>
  );
};

export default ChevronRightIcon;
