import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import OverflowTooltip from "../OverflowTooltip";
import { ChevronDownIcon } from "../../assets/IconsSVG";

import { styled, useTheme } from "@mui/material/styles";
import { useCallback } from "react";

type SelectItem = {
  text: string;
  value: string | number | boolean;
};

type SelectProps = {
  value: string | number | boolean | undefined;
  selectItems: SelectItem[] | null;
  action?: ((arg: SelectItem) => React.ReactNode) | null;
  error?: boolean;
  dataTestid?: string;
  children?: React.ReactNode;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  [x: string]: any;
} & TextFieldProps;

export const SelectEl = styled(
  ({
    handleChange,
    value,
    selectItems,
    action = null,
    error = false,
    label = "",
    dataTestid = "",
    helperText,
    children,
    placeholder,
    ...props
  }: SelectProps) => {
    const theme = useTheme();

    const renderValueFn = useCallback(
      (value: any) => {
        if (value === null || value.length === 0) {
          return <span>{placeholder}</span>;
        }

        let returnValue: string | number | boolean | undefined = "";

        returnValue = (selectItems as FoundationApp.SelectItem[]).find(
          (item) => {
            if (typeof value === "number") {
              return item.value === value.toString();
            } else {
              return item.value === value;
            }
          }
        )?.text;

        return returnValue;
      },
      [placeholder, selectItems]
    );

    return (
      <FormControl
        fullWidth
        sx={{
          "& .MuiFormControl-root": {
            borderRadius: "2.5rem !important",
          },
        }}
      >
        {selectItems && (
          <TextField
            fullWidth
            autoComplete="off"
            select
            variant="outlined"
            value={value}
            label={label}
            onChange={handleChange}
            error={Boolean(error)}
            helperText={helperText}
            inputProps={{ "data-testid": dataTestid }}
            SelectProps={{
              displayEmpty: true,
              renderValue: renderValueFn,
              IconComponent: (props) => {
                return (
                  <ChevronDownIcon
                    className={props.className}
                    mode={theme.palette.mode}
                  />
                );
              },
              MenuProps: {
                disableAutoFocusItem: true,
              },
            }}
            {...props}
          >
            {selectItems.map((selectItem, idx) => {
              return (
                <MenuItem
                  id={selectItem.text}
                  value={selectItem.value as any}
                  key={selectItem.value as any}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <OverflowTooltip
                        title={selectItem.text}
                        placement="bottom"
                      >
                        {selectItem.text}
                      </OverflowTooltip>

                      {idx !== 0 && action && action(selectItem)}
                    </>
                  </Box>
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </FormControl>
    );
  }
)(({ theme }) => ({
  "& .MuiSelect-icon": {
    marginTop: ".5rem",
  },
}));

export default SelectEl;
