import { createSlice } from "@reduxjs/toolkit";

import { ProfileState } from "@app-types/profile.model";

import {
  getProfileByIdAdminPanel,
  getProfileInfo,
  getProfileForEdit,
  getProfileByIdOrgPanel,
  editProfile,
  addPhoto,
  deletePhoto,
} from "./thunks";

const initialState: ProfileState = {
  loadingStatus: false,
  profileInfo: null,
  profileById: null,
  profileForEdit: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfileById: (state) => {
      state.profileById = null;
    },

    resetProfileSlice: () => initialState,
  },
  extraReducers: (builder) => {
    // ========================================================
    builder
      .addCase(getProfileByIdAdminPanel.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(getProfileByIdAdminPanel.fulfilled, (state, action) => {
        state.profileById = action.payload;
        state.loadingStatus = false;
      })
      .addCase(getProfileByIdAdminPanel.rejected, (state, action) => {
        state.profileById = null;
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(getProfileInfo.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(getProfileInfo.fulfilled, (state, action) => {
        state.profileInfo = action.payload;
        state.loadingStatus = false;
      })
      .addCase(getProfileInfo.rejected, (state, action) => {
        state.profileInfo = null;
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(getProfileForEdit.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(getProfileForEdit.fulfilled, (state, action) => {
        state.profileForEdit = action.payload;
        state.loadingStatus = false;
      })
      .addCase(getProfileForEdit.rejected, (state, action) => {
        state.profileInfo = null;
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(getProfileByIdOrgPanel.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(getProfileByIdOrgPanel.fulfilled, (state, action) => {
        state.profileById = action.payload;
        state.loadingStatus = false;
      })
      .addCase(getProfileByIdOrgPanel.rejected, (state, action) => {
        state.profileById = null;
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(editProfile.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        const {
          firstName,
          lastName,
          secondName,
          phoneNumber,
          isMan,
          birthday,
        } = action.payload;

        state.profileInfo!.name = `${lastName} ${firstName} ${secondName}`;
        state.profileInfo!.phoneNumber = phoneNumber!;
        state.profileInfo!.isMan = isMan;
        state.profileInfo!.birthday = birthday;

        state.profileForEdit!.birthday = birthday;
        state.profileForEdit!.lastName = lastName;
        state.profileForEdit!.firstName = firstName;
        state.profileForEdit!.secondName = secondName;
        state.profileForEdit!.phoneNumber = phoneNumber;
        state.profileForEdit!.isMan = isMan;

        state.loadingStatus = false;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.profileById = null;
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(addPhoto.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(addPhoto.fulfilled, (state, action) => {
        state.profileInfo = action.payload;
        state.loadingStatus = false;
      })
      .addCase(addPhoto.rejected, (state, action) => {
        state.loadingStatus = false;
      });
    // ========================================================
    builder
      .addCase(deletePhoto.pending, (state, action) => {
        state.loadingStatus = true;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.profileInfo = action.payload;
        state.loadingStatus = false;
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.loadingStatus = false;
      });
    // ========================================================
  },
});

const { reducer, actions } = profileSlice;

export const { clearProfileById, resetProfileSlice } = actions;
export default reducer;
