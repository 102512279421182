// @ts-nocheck
let USER_BACKEND = null;

if (window._env_ !== undefined) {
  USER_BACKEND = window._env_.REACT_APP_USER_API_URL;
} else {
  USER_BACKEND = process.env.REACT_APP_USER_API_URL;
}

// ---------------------------- USER ENTITY ---------------------------------

// USER ----->  Admin
export const USER_ADMIN = `${USER_BACKEND}/Admin`;

// USER -----> AdminPanel
export const USERS_ADMIN_PANEL = `${USER_BACKEND}/AdminPanel`;
export const ADD_USER_ADMIN_PANEL = `${USERS_ADMIN_PANEL}/register`;
export const EDIT_USER_ADMIN_PANEL = `${USERS_ADMIN_PANEL}/edit`;
export const REMOVE_USER_ADMIN_PANEL = `${USERS_ADMIN_PANEL}/removeuser`;
export const GET_USER_PROFILE_BY_ID_FOR_EDIT_ADMIN_PANEL = `${USERS_ADMIN_PANEL}/edit`;

//USER -----> Company
export const COMPANY = `${USER_BACKEND}/Company`;
export const COMPANY_LIST = `${COMPANY}/selectlist`;

//USER -----> Jobs
export const JOB = `${USER_BACKEND}/JobTitle`;
export const JOBS_LIST = `${USER_BACKEND}/JobTitle/selectlist`;

// USER -----> Dispatcher
export const USER_DISPATCHER = `${USER_BACKEND}/Dispatcher`;
export const USER_DISPATCHER_ADMIN = `${USER_BACKEND}/Dispatcher/all`;

// USER ----> Driver
export const USER_DRIVER = `${USER_BACKEND}/Driver`;
export const USER_DRIVER_ADMIN = `${USER_BACKEND}/Driver/all`;

// USER ------> Medical
export const USER_MEDICAL = `${USER_BACKEND}/Medical`;
export const USER_MEDICAL_ADMIN = `${USER_BACKEND}/Medical/all`;

//USER -------> Profile
export const CURRENT_PROFILE = `${USER_BACKEND}/Profile`;
export const ADD_USER_ORG_PANEL = `${CURRENT_PROFILE}/register`;
export const EDIT_PROFILE = `${CURRENT_PROFILE}/edit`;
export const EDIT_ORG_PANEL_USER = `${CURRENT_PROFILE}/edit`;
export const GET_USER_PROFILE_FOR_EDIT = `${CURRENT_PROFILE}/edit`;
export const REMOVE_USER = `${CURRENT_PROFILE}/removeuser`;
export const GET_USER_PROFILE_BY_ID_FOR_EDIT = `${CURRENT_PROFILE}/edit`;
export const ADD_PHOTO = `${CURRENT_PROFILE}/setphoto`;
export const DELETE_PHOTO = `${CURRENT_PROFILE}/removephoto`;

// USER -----> Role
export const USERS_ROLES = `${USER_BACKEND}/Role/selectList`;

// USER ------> Supervisor
export const USER_SUPERVISOR = `${USER_BACKEND}/Supervisor`;

// USER ---> Worker
export const WORKERS = `${USER_BACKEND}/Worker/selectlist`;

// -------------------------------------------------------------------------------
