import { Components, Theme } from "@mui/material/styles";

const Form = (theme: Theme): Components<Theme> => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
  };
};

export default Form;
