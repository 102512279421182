import { useAppDispatch, useAppSelector } from "@utils/hooks/redux-hooks";
import {
  selectNotification,
  clearMessage,
} from "@redux/slices/toast/toastSlice";

import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Slide, { SlideProps } from "@mui/material/Slide";
import { Portal } from "@mui/base";
import { SxProps } from "@mui/material";

const TransitionDown = (props: SlideProps) => {
  return <Slide {...props} direction="down" />;
};

interface IToast {
  style?: React.CSSProperties | SxProps;
}

const Toast: React.FC<IToast> = ({ style = null }) => {
  const dispatch = useAppDispatch();
  const { type, message, open } = useAppSelector(selectNotification);

  const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onClose = () => {
    dispatch(clearMessage());
  };

  return (
    <Portal>
      <Snackbar
        TransitionComponent={TransitionDown}
        onClose={onClose}
        data-testid="toast-message"
        open={open}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ ...style }}
      >
        <Alert severity={type || "warning"} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default Toast;
