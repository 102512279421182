import { styled } from "@mui/system";

import { BoxProps, Box } from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";

type CustomLinkProps = {
  active: boolean;
  [x: string]: any;
} & NavLinkProps;

export const StyledLinkContainer = styled(Box)<BoxProps>`
  width: 100%;
  height: 5.6rem;
  position: relative;
`;

export const StyledLink = styled((props: CustomLinkProps) => (
  <NavLink {...props} />
))(({ theme, active }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  textDecoration: "none",
  borderLeft: active ? `5px solid #4c7cf8` : "5px solid transparent",
  color: active ? "#ffff" : "#7C8A9E",

  img: active
    ? {
        filter: "drop-shadow(0px 1000px 0 white)",
        transform: "transform: translateY(-1000px)",
      }
    : "",
}));

export const StyledBackgroundImage = styled("img")`
  border-radius: 1rem;
  z-index: 1;
  height: 100%;
  position: absolute;
  top: -13%;
  left: -35%;
`;
