import { colors } from "./colors";

export const light = {
  labelPrimary: {
    main: "#071224",
    light: "#071224",
    dark: "#071224",
    contrastText: "#071224"
  },

  primary: {
    main: "#071224",
    contrastText: colors.white,
  },

  secondary: {
    main: "#E7F4FF",
    contrastText: "#071224",
  },

  success: {
    main: "#4C7CF8",
    contrastText: "#F6FBFF",
  },

  error: {
    main: "#FF4D4D",
    contrastText: "#F6FBFF",
  },

  text: {
    primary: "#071224",
    secondary: "#FFFFFF",
  },

  background: {
    paper: "#FFFFFF",
    default: "#FFFFFF",
  },

  agreeCardButton: {
    main: "#4C7CF8",
    contrastText: "#FFFFFF",
  },

  declineCardButton: {
    main: "#E2EAF5",
    contrastText: "#071224",
  },

  dialogButton: {
    main: "#D3E0F1",
    contrastText: "#071224",
  },

  //////////////////////////////

  navlink: {
    borderLeft: colors.blue[300],
  },

  actionBar: {
    chip: {
      bg: "",
      textColor: "",
    },
  },

  chip: {
    bg: colors.blue[50],
  },

  dialog: {
    bg: colors.white,
  },

  pagination: {
    select: {
      border: colors.gray[300],
      iconColor: colors.black[200],
      textColor: colors.black[200],
    },
    item: {
      iconColor: colors.black[200],
    },
  },

  sidebar: {
    bg: colors.black[200],
  },

  table: {
    footer: {
      border: colors.blue[50],
    },
    header: {
      border: colors.blue[50],
    },
    row: {
      bg: colors.gray[100],
      hover: {
        bg: colors.blue[50],
        chipTextColor: colors.white,
      },
    },
    selectedRow: {
      bg: colors.black[200],
      textColor: colors.blue[100],
    },
  },

  modal: {
    bg: colors.white,
    icon: colors.black[200],
    textFieldBg: colors.black[600],
    textFieldIcon: colors.gray[400],
  },

  scrollbar: {
    trackBg: colors.gray[100],
    thumbBg: colors.gray[800],
    thumbHover: colors.gray[900],
  },

  outlineInput: {
    bg: colors.blue[50],
    color: colors.gray[700],
  },

  inputLabel: {
    color: colors.black[200],
  },

  menu: {
    hover: colors.blue[50],
    selected: colors.blue[50],
    textColor: colors.black[200],
    // scrollbarColor: colors.gray[800],
  },

  searchTextField: {
    bg: colors.gray[100],
    textColor: colors.gray[700],
  },

  input: {
    textColor: colors.black[600],
    adornedEnd: {
      border: colors.gray[400],
    },
  },

  signin: {
    bg: colors.white,
  },

  card: {
    preliminary: {
      bad: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FF4C4CFF' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
      normal: {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%234C7CF8FF' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
    },
    agreed: {
      bad: "1px solid #FF4C4C",
      normal: "1px solid #4C7CF8",
    },
    archive: {
      bad: "#FF4C4C",
      normal: "#4C7CF8",
    },
  },
};
